import React, { useEffect, useState, useRef } from "react"
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode"
import { useSelector, useDispatch } from "react-redux"
import NotificationModal from "../../../Modals/NotificationModal"
import Toast from "../../../common/Toast/Toast"
import YesNoModal from "../../../Modals/YesNoModal"
import YesNoPopup from "../../../Modals/YesNoPopup/yesNoPopup.js"

import {
  getOrderByPackageOrNumber,
  clearOrderByPackageOrNumber,
  saveDamagedPackage,
  clearDamagedPackage,
  createDamagedPackagesReport,
  clearCreateDamagedPackagesReport,
  getLastReport,
} from "../../../../actions"
import "./DamagedPackagesPage.scss"

const DamagedPackagesPage = (props) => {
  const [barcode, setBarcode] = useState("")
  const [orderData, setOrderData] = useState(null)
  const [image, setImage] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [isBarcodeScanned, setIsBarcodeScanned] = useState(false)
  const [scannerReady, setScannerReady] = useState(true)
  const [orderNotFound, setOrderNotFound] = useState(false)
  const [manualBarcode, setManualBarcode] = useState("")
  const [showConfirmationReportPopup, setShowConfirmationReportPopup] =
    useState(false)
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const scannerRef = useRef(null)
  const dispatch = useDispatch()
  const {
    isOrderByPackageOrNumberSuccess,
    isOrderByPackageOrNumberFail,
    orderByPackageOrNumber,
  } = useSelector((state) => state.ordersList)
  const {
    saveDamagedPackageSuccess,
    saveDamagedPackageFail,
    damagedPackagesReportSuccess,
    damagedPackagesReportFail,
    damagedPackagesReport,
    saveDamagedPackageConflict,
    damagedPackagesReportNotFound,
    isReportLoading,
  } = useSelector((state) => state.damagedPackage)

  useEffect(() => {
    return () => {
      setBarcode("")
      setOrderData(null)
      setImage(null)
      setIsBarcodeScanned(false)
      setScannerReady(true)
      dispatch(clearOrderByPackageOrNumber())
    }
  }, [])

  useEffect(() => {
    if (scannerReady) {
      scannerRef.current = new Html5QrcodeScanner("barcode-scanner", {
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        qrbox: {
          width: 300,
          height: 200,
        },
        fps: 5,
      })

      scannerRef.current.render(
        (decodedText) => {
          setBarcode(decodedText)
          dispatch(getOrderByPackageOrNumber(decodedText))
          setIsBarcodeScanned(true)
          scannerRef.current.clear()
          setScannerReady(false)
        },
        (errorMessage) => {
          console.warn("Barcode scan error:", errorMessage)
        }
      )

      return () => {
        if (scannerRef.current) {
          scannerRef.current.clear()
        }
      }
    }
  }, [scannerReady])

  useEffect(() => {
    if (isBarcodeScanned && isOrderByPackageOrNumberSuccess) {
      setOrderNotFound(false)
      const startCamera = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "environment" },
          })
          if (videoRef.current) {
            videoRef.current.srcObject = stream
          }
        } catch (error) {
          console.error("Error accessing camera:", error)
        }
      }
      startCamera()
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          videoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop())
        }
      }
    }
  }, [isBarcodeScanned, isOrderByPackageOrNumberSuccess])

  useEffect(() => {
    if (isOrderByPackageOrNumberSuccess) {
      setOrderData(orderByPackageOrNumber)
    }
  }, [isOrderByPackageOrNumberSuccess])

  useEffect(() => {
    if (saveDamagedPackageSuccess || isOrderByPackageOrNumberFail) {
      resetState()
    }
  }, [saveDamagedPackageSuccess, isOrderByPackageOrNumberFail])

  useEffect(() => {
    if (isOrderByPackageOrNumberFail) {
      setOrderNotFound(true)
    }
  }, [isOrderByPackageOrNumberFail])

  const handleManualBarcodeSubmit = () => {
    if (manualBarcode.trim()) {
      setBarcode(manualBarcode)
      dispatch(getOrderByPackageOrNumber(manualBarcode))
      setIsBarcodeScanned(true)
      setScannerReady(false)
    }
  }

  const takePicture = () => {
    setScannerReady(false)
    const canvas = canvasRef.current
    const video = videoRef.current

    if (canvas && video) {
      const context = canvas.getContext("2d")
      const targetWidth = 1024
      const targetHeight = (video.videoHeight / video.videoWidth) * targetWidth
      canvas.width = targetWidth
      canvas.height = targetHeight
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      const imageData = canvas.toDataURL("image/jpeg", 0.6)
      setImagePreview(imageData)
      const file = base64ToFile(imageData, `photo_${Date.now()}.jpg`)
      setImage(file)
    }
  }

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",")
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const submitData = () => {
    if (orderData && image) {
      dispatch(saveDamagedPackage(orderData.id, image))
    }
  }

  const resetState = () => {
    setOrderData(null)
    setImage(null)
    setBarcode("")
    setIsBarcodeScanned(false)
    setScannerReady(true)
    dispatch(clearOrderByPackageOrNumber())
    dispatch(clearDamagedPackage())
  }

  return (
    <section className="damaged-packages-page">
      <div className="damaged-packages-container">
        {!isBarcodeScanned && (
          <>
            <h2>סרוק ברקוד או הזן ידנית</h2>
            <div id="barcode-scanner"></div>

            <div className="manual-barcode-container">
              <input
                type="text"
                value={manualBarcode}
                onChange={(e) => setManualBarcode(e.target.value)}
                placeholder="הזן ברקוד ידנית"
              />
              <button
                className="manual-barcode-btn"
                onClick={handleManualBarcodeSubmit}
              >
                אישור
              </button>
            </div>
          </>
        )}
        {orderNotFound && <p className="error">הזמנה לא נמצאה</p>}
        {isReportLoading ? (
          <p className="loading">טוען דוח...</p>
        ) : (
          <div className="report-btns-wrapper">
            <button
              className="report-btn"
              onClick={() => setShowConfirmationReportPopup(true)}
            >
              הוצא דוח
            </button>
            <button
              className="report-btn"
              onClick={() => dispatch(getLastReport())}
            >
              דוח אחרון
            </button>
          </div>
        )}

        {isOrderByPackageOrNumberSuccess && (
          <>
            <h2>צלם תמונה של חבילה פגומה</h2>
            <h4>חבילה מספר: {orderData?.packageNumber}</h4>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className="camera-feed"
            ></video>
            <div className="buttons">
              <button onClick={takePicture}>📷 צלם</button>
              {image && (
                <button className="save-btn" onClick={submitData}>
                  💾 שמור
                </button>
              )}
              <button className="reset-btn" onClick={resetState}>
                🔄 חזור
              </button>
            </div>
            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
          </>
        )}
        {image && (
          <div className="captured-container">
            <img src={imagePreview} alt="Captured" className="captured-image" />
          </div>
        )}
      </div>
      <Toast
        isShow={saveDamagedPackageSuccess}
        message={"הזמנה נשמרה"}
        position="bottom-right"
      />
      <NotificationModal
        type={"error"}
        show={saveDamagedPackageFail}
        title={"הזמנות פגומות"}
        text={"שגיאה בתהליך, החבילה לא נשמרה."}
        onOkClick={() => resetState()}
      />
      <NotificationModal
        type={"error"}
        show={saveDamagedPackageConflict}
        title={"הזמנות פגומות"}
        text={"הזמנה זאת כבר נשמרה במערכת כפגומה."}
        onOkClick={() => resetState()}
      />
      <NotificationModal
        type={"warning"}
        show={damagedPackagesReport}
        title={"הזמנות פגומות"}
        text={"לא נמצאו הזמנות"}
        onOkClick={() => resetState()}
      />
      <NotificationModal
        type={"warning"}
        show={damagedPackagesReportNotFound}
        title={"הזמנות פגומות"}
        text={"לא נמצאו הזמנות פגומות לדווח."}
        onOkClick={() => dispatch(clearCreateDamagedPackagesReport())}
      />
      <YesNoPopup
        isOpen={showConfirmationReportPopup}
        infoMsg={"האם תרצה להפיק דוח של כל ההזמנות שנסרקו עד כה?"}
        close={() => setShowConfirmationReportPopup(false)}
        confirm={(e) => {
          dispatch(createDamagedPackagesReport())
          setShowConfirmationReportPopup(false)
        }}
      />
    </section>
  )
}

export default DamagedPackagesPage
