import {
  GET_STORAGE_SACK_NUMBERS_SUCSESS,
  GET_STORAGE_SACK_NUMBERS_FAIL,
  ON_CLOSE_STORAGE_SACK_REQUEST,
  ON_CLOSE_STORAGE_SACK_SUCCESS,
  CLEAR_CLOSE_STORAGE_SACK,
  ON_CLOSE_STORAGE_SACK_FAIL,
  ON_ORDER_IN_SACK_SUCCESS,
  ON_ORDER_IN_SACK_FAIL,
  ON_SEARCH_STORAGE_SACK_REQUEST,
  ON_SEARCH_STORAGE_SACK_SUCCESS,
  ON_SEARCH_STORAGE_SACK_FAIL,
  CLEAR_SEARCH_STORAGE_SACKS,
  ON_GET_STORAGE_SACK_ORDERS_SUCCESS,
  ON_GET_STORAGE_SACK_ORDERS_FAIL,
  ON_CLOSE_ALL_STORAGE_SACKS_REQUEST,
  ON_CLOSE_ALL_STORAGE_SACKS_SUCCESS,
  ON_CLOSE_ALL_STORAGE_SACKS_FAIL,
  CLEAR_CLOSE_ALL_STORAGE_SACKS,
  GET_SACK_SUMMARY_BY_STATION_SUCCESS,
  GET_SACK_SUMMARY_BY_STATION_FAIL,
  ON_SEARCH_STORAGE_SACK_BY_DATE_SUCCESS,
  ON_SEARCH_STORAGE_SACK_BY_DATE_FAIL,
} from "../actions/types"

import {} from "../utils/EnumsAndConsts"

const INIT_STATE = {
  isloading: false,
  isSearchSackLoading: false,
  storageSackNumbers: [],
  storageSackClosedSuccess: false,
  storageSackClosedFail: false,
  ordersInSackList: [],
  getOrdersInSackSuccess: false,
  getOrdersInSackFail: false,
  storageSack: {},
  searchStorageSackSuccess: false,
  searchStorageSackFail: false,
  searchStorageSackResult: null,
  searchSackOrdersSuccess: false,
  searchSackOrdersResult: null,
  allStorageSacksClosedSuccess: false,
  allStorageSacksClosedFail: false,
  allStorageSacksList: null,
  getSummaryByStationSuccess: false,
  getSummaryByStationFail: false,
  sacksSummaryByStationList: [],
}

const OrderStorageSackReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORAGE_SACK_NUMBERS_SUCSESS:
      return {
        ...state,
        storageSackNumbers: action.payload,
      }

    case GET_STORAGE_SACK_NUMBERS_FAIL:
      return {
        ...state,
        storageSackNumbers: [],
      }

    case ON_CLOSE_STORAGE_SACK_REQUEST:
      return {
        ...state,
        isloading: true,
        storageSackClosedSuccess: false,
        storageSackClosedFail: false,
      }

    case ON_CLOSE_STORAGE_SACK_SUCCESS:
      return {
        ...state,
        isloading: false,
        storageSack: action.payload,
        storageSackClosedSuccess: true,
        storageSackClosedFail: false,
      }
    case CLEAR_CLOSE_STORAGE_SACK:
      return {
        ...state,
        isloading: false,
        storageSack: {},
        ordersInSackList: [],
        storageSackClosedSuccess: false,
        storageSackClosedFail: false,
      }

    case ON_CLOSE_STORAGE_SACK_FAIL:
      return {
        ...state,
        isloading: false,
        storageSackClosedSuccess: false,
        storageSackClosedFail: true,
      }
    case ON_ORDER_IN_SACK_SUCCESS:
      return {
        ...state,
        ordersInSackList: action.payload,
        getOrdersInSackSuccess: true,
        getOrdersInSackFail: false,
      }

    case ON_ORDER_IN_SACK_FAIL:
      return {
        ...state,
        getOrdersInSackSuccess: false,
        getOrdersInSackFail: true,
      }

    case ON_SEARCH_STORAGE_SACK_REQUEST:
      return {
        ...state,
        isSearchSackLoading: true,
        searchStorageSackSuccess: false,
        searchStorageSackFail: false,
      }
    case ON_SEARCH_STORAGE_SACK_SUCCESS:
      return {
        ...state,
        searchStorageSackSuccess: true,
        searchStorageSackFail: false,
        searchStorageSackResult: action.payload,
        isSearchSackLoading: false,
        isError: false,
      }
    case ON_SEARCH_STORAGE_SACK_FAIL:
      return {
        ...state,
        searchStorageSackSuccess: false,
        searchStorageSackFail: true,
        searchStorageSackResult: null,
        isSearchSackLoading: false,
        isError: true,
      }
    case CLEAR_SEARCH_STORAGE_SACKS:
      return {
        ...state,
        ordersInSackList: [],
        searchStorageSackResult: null,
        searchStorageSackSuccess: false,
        searchStorageSackFail: false,
        searchSackOrdersSuccess: false,
        searchSackOrdersResult: null,
        isSearchSackLoading: false,
        isError: false,
      }
    case ON_GET_STORAGE_SACK_ORDERS_SUCCESS:
      return {
        ...state,
        searchSackOrdersSuccess: true,
        searchSackOrdersResult: action.payload,
        isLoading: false,
        isError: false,
      }
    case ON_GET_STORAGE_SACK_ORDERS_FAIL:
      return {
        ...state,
        searchSackOrdersSuccess: false,
        searchSackOrdersResult: null,
        isLoading: false,
        isError: true,
      }
    case ON_CLOSE_ALL_STORAGE_SACKS_REQUEST:
      return {
        ...state,
        isloading: true,
        allStorageSacksClosedSuccess: false,
        allStorageSacksClosedFail: false,
      }

    case ON_CLOSE_ALL_STORAGE_SACKS_SUCCESS:
      return {
        ...state,
        isloading: false,
        allStorageSacksList: action.payload,
        allStorageSacksClosedSuccess: true,
        allStorageSacksClosedFail: false,
      }
    case ON_CLOSE_ALL_STORAGE_SACKS_FAIL:
      return {
        ...state,
        isloading: false,
        allStorageSacksClosedSuccess: false,
        allStorageSacksClosedFail: true,
      }
    case CLEAR_CLOSE_ALL_STORAGE_SACKS:
      return {
        ...state,
        isloading: false,
        allStorageSacksList: null,
        allStorageSacksClosedSuccess: false,
        allStorageSacksClosedFail: false,
      }
    case GET_SACK_SUMMARY_BY_STATION_SUCCESS:
      return {
        ...state,
        isloading: false,
        getSummaryByStationSuccess: true,
        getSummaryByStationFail: false,
        sacksSummaryByStationList: action.payload,
      }
    case GET_SACK_SUMMARY_BY_STATION_FAIL:
      return {
        ...state,
        isloading: false,
        getSummaryByStationSuccess: false,
        getSummaryByStationFail: true,
        sacksSummaryByStationList: [],
      }
    default:
      return state
  }
}

export default OrderStorageSackReducer
