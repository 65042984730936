import React from "react"

import Header from "../../../../../common/List/Header"
import { H1, Spinner } from "../../../../../common"
import SackItem from "./SackItem"

import "./style.scss"

const sackHeaders = [
  "הדפסה",
  "ברקוד",
  "כמות הזמנות בתעודה",
  "סטטוס",
  "סוג",
  "תאריך יצירה",
  "",
]
const sackItemMap = [
  "print",
  "barcode",
  "ordersCount",
  "orderStatus",
  "accountType",
  "createdDate",
  "setAsDelevered",
]
const sackGravity = {}

const SacksList = ({
  extraClass,
  items,
  isError,
  isLoading,
  history,
  onDeliverSackClick,
  onPrintClick,
  accountTypes,
}) => {
  return (
    <div className={`sack-orders-list  list-wrapper ${extraClass}`}>
      <Header titles={sackHeaders} gravity={sackGravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          sackGravity,
          sackItemMap,
          isError,
          isLoading,
          history,
          onDeliverSackClick,
          accountTypes,
          onPrintClick
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  sackGravity,
  sackItemMap,
  isError,
  isLoading,
  history,
  onDeliverSackClick,
  accountTypes,
  onPrintClick
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (isLoading) {
    return (
      <div className="list-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <SackItem
        key={index}
        item={itm}
        itemMap={sackItemMap}
        gravity={sackGravity}
        isLast={index === items.length - 1}
        history={history}
        onDeliverSackClick={onDeliverSackClick}
        accountTypes={accountTypes}
        onPrintClick={onPrintClick}
      />
    )
  })
}

export default SacksList
