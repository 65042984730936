import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"
import {
  ON_GET_USERS_LIST_SUCCESS,
  ON_GET_USERS_LIST_FAIL,
  ON_GET_USER_DETAILS_SUCCESS,
  ON_GET_COUNTRIES_SUCCESS,
  ON_USERS_LOADING,
  ON_USERS_SEARCH_NOT_VALID_INPUT,
  REMOVE_NOT_VALID_INPUT,
  SET_USERS_SEARCH,
  ON_USERS_SEARCH_SUCCESS,
  CLEAR_USER_SEARCH,
  ON_BLOCK_CUSTOMER_SUCCESS,
  ON_BLOCK_CUSTOMER_FAIL,
  ON_UNBLOCK_CUSTOMER_SUCCESS,
  ON_UNBLOCK_CUSTOMER_FAIL,
  ON_CHECK_USER_BLACK_LIST,
} from "./types"

export const fetchAllUsers = () => {
  return (dispatch) => {
    axios
      .get(api.getAllUsers)
      .then((res) => {
        dispatch({
          type: ON_GET_USERS_LIST_SUCCESS,
          payload: api.getAllUsersResponse(res.data),
        })
        console.log("SUCCESS ON GET ALL USERS", res.data)
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_USERS_LIST_FAIL,
          payload: err,
        })
        console.log("FAIL ON GET ALL USERS", err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchUsersByName = (searchText) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const formData = searchText.split(" ")
    axios
      .post(api.getUsersByName, formData, options)
      .then((res) => {
        dispatch({
          type: ON_GET_USERS_LIST_SUCCESS,
          payload: api.getAllUsersResponse(res.data),
        })
        console.log("SUCCESS ON GET USERS BY NAME", res.data)
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_USERS_LIST_FAIL,
          payload: err,
        })
        console.log("FAIL ON GET USERS BY NAME", err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchUserByMobile = (mobile) => {
  return (dispatch) => {
    axios
      .get(api.getUserByMobile(mobile))
      .then((res) => {
        dispatch({
          type: ON_GET_USERS_LIST_SUCCESS,
          payload: api.getAllUsersResponse([res.data]),
        })
        console.log("SUCCESS ON GET USER BY Mobile", res.data)
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_USERS_LIST_FAIL,
          payload: err,
        })
        console.log("FAIL ON GET USER BY Mobile", err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const setUsersSearch = (usersSearch) => {
  return (dispatch) => {
    dispatch({
      type: SET_USERS_SEARCH,
      payload: usersSearch,
    })
  }
}

export const clearUsersSearch = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_SEARCH,
    })
  }
}

export const searchUsers = (usersSearch) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.searchUsers, usersSearch, options)
      .then((res) => {
        dispatch({
          type: ON_USERS_SEARCH_SUCCESS,
          payload: res.data,
        })
        console.log("SUCCESS SEARCH USERS", res.data)
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_USERS_LIST_FAIL,
          payload: err,
        })
        console.log("FAIL SEARCH USERS", err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const onStartLoadingUsers = () => {
  return (dispatch) => {
    dispatch({
      type: ON_USERS_LOADING,
    })
  }
}
export const onUsersSearchNotValidInput = () => {
  return (dispatch) => {
    dispatch({
      type: ON_USERS_SEARCH_NOT_VALID_INPUT,
    })
  }
}

export const removeNotValidInputMessage = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_NOT_VALID_INPUT,
    })
  }
}

export const getUserDetails = (mobile) => {
  return (dispatch) => {
    axios
      .get(api.getUserByMobile(mobile))
      .then((res) => {
        dispatch({
          type: ON_GET_USER_DETAILS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => console.log(err))
  }
}

export const blockCustomer = (userId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.blockCustomer(userId), options)
      .then((res) => {
        dispatch({
          type: ON_BLOCK_CUSTOMER_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_BLOCK_CUSTOMER_FAIL,
          payload: err,
        })
      })
  }
}

export const unblockCustomer = (userId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.unblockCustomer(userId), options)
      .then((res) => {
        dispatch({
          type: ON_UNBLOCK_CUSTOMER_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UNBLOCK_CUSTOMER_FAIL,
          payload: err,
        })
      })
  }
}

export const checkUserBlackList = (mobile) => {
  return (dispatch) => {
    axios.get(api.checkUserBlackList(mobile)).then((res) => {
      dispatch({
        type: ON_CHECK_USER_BLACK_LIST,
        payload: res.data,
      })
    })
  }
}
