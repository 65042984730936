import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { connect } from "react-redux"
import Select from "react-select"
import {
  getBranchCardByBranchId,
  editBranchCard,
  clearUpdateBranchCard,
  clearGetBranchCard,
} from "../../../../actions/BranchCardAction"
import { BlueButton } from "../../../common"
import NotificationModal from "../../../Modals/NotificationModal"
import "./BranchCard.scss"

const BranchCard = (props) => {
  const paymentTypeOptions = [
    { value: 0, label: "לא ידוע" },
    { value: 1, label: "הרשאה" },
    { value: 2, label: "הוראת קבע" },
    { value: 3, label: "צקים שנתי" },
  ]

  const [selectedPaymentType, setSelectedPaymentType] = useState(null)
  const [branchIdFromStation, setBranchIdFromStation] = useState(null)
  const [formData, setFormData] = useState({
    branchId: "",
    cost: null,
    branchContractPaymentType: paymentTypeOptions[0].value,
    contractExpiration: "",
    contractStart: "",
    companyName: "",
    contractFilePath: "",
    indexation: "",
    guarantee: "",
    propertyManagerName: "",
    propertyManagerPhone: "",
    propertyManagerEmail: "",
    maintenanceWorkerName: "",
    maintenanceWorkerPhone: "",
    maintenanceWorkerEmail: "",
    maintenanceWorkerJob: "",
    accountManagementName: "",
    accountManagementPhone: "",
    accountManagementEmail: "",
    accountManagementJob: "",
    paymentComments: "",
  })

  useEffect(() => {
    if (props.logedUserData == null) {
      props.history.push("/")
    }
    const queryParams = new URLSearchParams(props.location.search)
    const branchId = queryParams.get("branchId")
    if (branchId) {
      setBranchIdFromStation(branchId)
      setFormData({
        ...formData,
        branchId: branchId,
      })
      props.getBranchCardByBranchId(branchId)
      setSelectedPaymentType(paymentTypeOptions[0])
    }
    return () => {
      setFormData(null)
      props.clearUpdateBranchCard()
      props.clearGetBranchCard()
    }
  }, [])

  useEffect(() => {
    const { branchCardItem } = props
    if (branchCardItem) {
      const contractExpirationformatted = dateForDatePicker(
        branchCardItem.contractExpiration
          ? branchCardItem.contractExpiration
          : ""
      )
      const contractStartformatted = dateForDatePicker(
        branchCardItem.contractStart ? branchCardItem.contractStart : ""
      )
      setFormData({
        branchId: branchCardItem?.branchId
          ? branchCardItem?.branchId
          : branchIdFromStation,
        cost: branchCardItem.cost,
        branchContractPaymentType: branchCardItem.branchContractPaymentType,
        contractExpiration: contractExpirationformatted,
        contractStart: contractStartformatted,
        companyName: branchCardItem.companyName,
        indexation: branchCardItem.indexation,
        guarantee: branchCardItem.guarantee,
        propertyManagerName: branchCardItem.propertyManagerName,
        propertyManagerPhone: branchCardItem.propertyManagerPhone,
        propertyManagerEmail: branchCardItem.propertyManagerEmail,
        maintenanceWorkerName: branchCardItem.maintenanceWorkerName,
        maintenanceWorkerPhone: branchCardItem.maintenanceWorkerPhone,
        maintenanceWorkerEmail: branchCardItem.maintenanceWorkerEmail,
        maintenanceWorkerJob: branchCardItem.maintenanceWorkerJob,
        accountManagementName: branchCardItem.accountManagementName,
        accountManagementPhone: branchCardItem.accountManagementPhone,
        accountManagementEmail: branchCardItem.accountManagementEmail,
        accountManagementJob: branchCardItem.accountManagementJob,
        contractFilePath: branchCardItem.contractFilePath,
        paymentComments: branchCardItem.paymentComments,
      })
      setSelectedPaymentType(
        paymentTypeOptions.filter(
          (option) => option.value === branchCardItem.branchContractPaymentType
        )
      )
    }
  }, [props.branchCardItem])

  const dateForDatePicker = (unformatedDate) => {
    if (unformatedDate == "" || unformatedDate == null) {
      return ""
    }
    const dateObject = new Date(unformatedDate)
    const israelTimeZoneOffset = 3 * 60
    const israelTime = new Date(
      dateObject.getTime() + israelTimeZoneOffset * 60000
    )
    const year = israelTime.getFullYear()
    const month = String(israelTime.getMonth() + 1).padStart(2, "0")
    const day = String(israelTime.getDate()).padStart(2, "0")
    const israelFormattedDate = `${year}-${month}-${day}`
    return israelFormattedDate
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleChangePaymentType = (item) => {
    setSelectedPaymentType(item)
    setFormData({ ...formData, branchContractPaymentType: item.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.editBranchCard(formData)
  }

  const refreshOnsuccess = () => {
    props.getBranchCardByBranchId(formData?.branchId)
    props.clearUpdateBranchCard()
  }

  const openLink = (event) => {
    if (formData?.contractFilePath) {
      event.preventDefault()
      window.open(formData?.contractFilePath, "_blank")
    }
  }

  return (
    <section className="BranchCard">
      <h2>יצירת כרטיס סניף</h2>
      <form>
        <div className="inputs-holder">
          <div>
            <label htmlFor="cost">עלות</label>
            <input
              type="number"
              name="cost"
              placeholder="עלות"
              value={formData.cost || ""}
              onChange={handleChange}
            />

            <label htmlFor="paymentType">סוג תשלום</label>
            <Select
              className="select-payment-type"
              isClearable={false}
              options={paymentTypeOptions}
              placeholder="בחר סוג תשלום"
              value={selectedPaymentType}
              onChange={handleChangePaymentType}
            />
            <label htmlFor="contractStart">תחילת חוזה </label>
            <input
              type="date"
              name="contractStart"
              value={formData.contractStart || ""}
              onChange={handleChange}
            />
            <label htmlFor="contractExpiration">תוקף חוזה</label>
            <input
              type="date"
              name="contractExpiration"
              value={formData.contractExpiration || ""}
              onChange={handleChange}
            />

            <label htmlFor="companyName">שם חברה</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName || ""}
              onChange={handleChange}
            />
            <div>
              <label htmlFor="contractFilePath">נתיב לקובץ חוזה</label>
              <div className="contract">
                <input
                  type="text"
                  name="contractFilePath"
                  value={formData.contractFilePath || ""}
                  onChange={handleChange}
                />
                <BlueButton extraClass="btn-download" onClick={openLink}>
                  הורד
                </BlueButton>
              </div>
            </div>
            <label htmlFor="indexation">הצמדה למדד</label>
            <input
              type="text"
              name="indexation"
              value={formData.indexation || ""}
              onChange={handleChange}
            />
            <label htmlFor="ערבות">ערבות</label>
            <input
              type="text"
              name="guarantee"
              value={formData.guarantee || ""}
              onChange={handleChange}
            />
            <label htmlFor="ערבות">הערות תשלום</label>
            <input
              type="text"
              name="paymentComments"
              value={formData.paymentComments || ""}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="propertyManagerName">שם מנהל הנכס</label>
            <input
              type="text"
              name="propertyManagerName"
              value={formData.propertyManagerName || ""}
              onChange={handleChange}
            />
            <label htmlFor="propertyManagerPhone">טלפון מנהל הנכס</label>
            <input
              type="text"
              name="propertyManagerPhone"
              value={formData.propertyManagerPhone || ""}
              onChange={handleChange}
            />
            <label htmlFor="propertyManagerEmail">אימייל מנהל הנכס</label>
            <input
              type="text"
              name="propertyManagerEmail"
              value={formData.propertyManagerEmail || ""}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="maintenanceWorkerName">שם איש אחזקה</label>
            <input
              type="text"
              name="maintenanceWorkerName"
              value={formData.maintenanceWorkerName || ""}
              onChange={handleChange}
            />
            <label htmlFor="maintenanceWorkerPhone">טלפון איש אחזקה</label>
            <input
              type="text"
              name="maintenanceWorkerPhone"
              value={formData.maintenanceWorkerPhone || ""}
              onChange={handleChange}
            />
            <label htmlFor="maintenanceWorkerEmail">אימייל איש אחזקה</label>
            <input
              type="text"
              name="maintenanceWorkerEmail"
              value={formData.maintenanceWorkerEmail || ""}
              onChange={handleChange}
            />
            <label htmlFor="maintenanceWorkerJob">תפקיד איש אחזקה</label>
            <input
              type="text"
              name="maintenanceWorkerJob"
              value={formData.maintenanceWorkerJob || ""}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="accountManagementName">שם מנהל חשבונות</label>
            <input
              type="text"
              name="accountManagementName"
              value={formData.accountManagementName || ""}
              onChange={handleChange}
            />
            <label htmlFor="accountManagementPhone">טלפון מנהל חשבונות</label>
            <input
              type="text"
              name="accountManagementPhone"
              value={formData.accountManagementPhone || ""}
              onChange={handleChange}
            />
            <label htmlFor="accountManagementEmail">אימייל מנהל חשבונות</label>
            <input
              type="text"
              name="accountManagementEmail"
              value={formData.accountManagementEmail || ""}
              onChange={handleChange}
            />
            <label htmlFor="accountManagementJob">תפקיד מנהל חשבונות</label>
            <input
              type="text"
              name="accountManagementJob"
              value={formData.accountManagementJob || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
      <BlueButton extraClass="btn-edit" onClick={handleSubmit}>
        שמור
      </BlueButton>
      <NotificationModal
        show={props.branchCardUpdateSuccess}
        type="success"
        title="הצלחה"
        text="כרטיס עודכן בהצלחה"
        onOkClick={() => refreshOnsuccess(formData?.branchId)}
      />
      <NotificationModal
        show={props.branchCardUpdateFail}
        type="error"
        title="כישלון"
        text="עדכון כרטיס נכשל"
        onOkClick={() => props.clearUpdateBranchCard()}
      />
    </section>
  )
}

const mapStateToProps = ({ branchCard, login }) => {
  const {
    getBranchCardSuccess,
    getBranchCardFail,
    branchCardItem,
    branchCardUpdateSuccess,
    branchCardUpdateFail,
  } = branchCard
  const { logedUserData } = login
  return {
    getBranchCardSuccess,
    getBranchCardFail,
    branchCardItem,
    branchCardUpdateSuccess,
    branchCardUpdateFail,
    logedUserData,
  }
}
export default connect(mapStateToProps, {
  getBranchCardByBranchId,
  editBranchCard,
  clearUpdateBranchCard,
  clearGetBranchCard,
})(BranchCard)
