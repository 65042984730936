import React, { useEffect } from "react"
import { Link, Route, Switch } from "react-router-dom"
import { useDispatch } from "react-redux"
import StationsPage from "./StationsPage"
import EditStation from "./EditStation"
import NewStation from "./NewStation"
import StationsNotifications from "./StationsNotifications"
import SendSMS from "./SendSMS"
import Routes from "./Routes"
import RouteStations from "./RouteStations"
import CitiesScreen from "../CitiesScreen"
import RouteAreaCodeScreen from "./RouteAreaCodeScreen/RouteAreaCodeScreen"
import StationTasksScreen from "./StationTasksScreen/StationTasksScreen"
import BranchCard from "./BranchCard/BranchCard"
import ExternalDriversScreen from "./ExternalDriversScreen"
import StationSacksScreen from "./StationSacksScreen/StationSacksScreen"
import StataionSnapshotsScreen from "./StataionSnapshotsScreen/StataionSnapshotsScreen"
import StationPhotos from "./StationPhotos/StationPhotos"

import { cleanStations } from "../../../actions"
import "./style.css"

const StationsScreen = ({ match }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(cleanStations())
    }
  }, [dispatch])

  return (
    <section id="stations-section-container">
      <nav id="stations-section-menu">
        <ul>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}`}>עמדות</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/create`}>עמדה חדשה</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/notifications`}>התראות</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/routes`}>ניהול מסלולי הפצה</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/route_cities`}>עדכון ערים</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/routeAreaCodes`}>עדכון קודי אזור</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/stationTasks`}>משימות עמדה</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/externalDrivers`}>נהגים חיצונים</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/staionSacks`}>שקים</Link>
          </li>
          <li className="stations-section-menu-item">
            <Link to={`${match.url}/snapshots`}>מצלמות</Link>
          </li>
        </ul>
      </nav>
      <main id="stations-section-body">
        <Switch>
          <Route exact path={`${match.path}`} component={StationsPage} />
          <Route path={`${match.path}/edit`} component={EditStation} />
          <Route path={`${match.path}/create`} component={NewStation} />
          <Route
            path={`${match.path}/notifications`}
            component={StationsNotifications}
          />
          <Route path={`${match.path}/sendSMS`} component={SendSMS} />
          <Route path={`${match.path}/routes`} component={Routes} />
          <Route
            path={`${match.path}/route_stations`}
            component={RouteStations}
          />
          <Route path={`${match.path}/route_cities`} component={CitiesScreen} />
          <Route
            path={`${match.path}/routeAreaCodes`}
            component={RouteAreaCodeScreen}
          />
          <Route
            path={`${match.path}/stationTasks`}
            component={StationTasksScreen}
          />
          <Route path={`${match.path}/branchCard`} component={BranchCard} />
          <Route
            path={`${match.path}/stationPhotos`}
            component={StationPhotos}
          />
          <Route
            path={`${match.path}/externalDrivers`}
            component={ExternalDriversScreen}
          />
          <Route
            path={`${match.path}/staionSacks`}
            component={StationSacksScreen}
          />
          <Route
            path={`${match.path}/snapshots`}
            component={StataionSnapshotsScreen}
          />
        </Switch>
      </main>
    </section>
  )
}

export default StationsScreen
