import axios from "axios"
import api from "../api"
import {
  SAVE_DAMAGED_PACKAGE_REQUEST,
  SAVE_DAMAGED_PACKAGE_SUCCESS,
  SAVE_DAMAGED_PACKAGE_FAIL,
  SAVE_DAMAGED_PACKAGE_CONFLICT,
  CLEAR_DAMAGED_PACKAGE,
  CREATE_DAMAGED_PACKAGES_REPORT_REQUEST,
  CREATE_DAMAGED_PACKAGES_REPORT_SUCCESS,
  CREATE_DAMAGED_PACKAGES_REPORT_FAIL,
  CREATE_DAMAGED_PACKAGES_REPORT_NOT_FOUND,
  CLEAR_CREATE_DAMAGED_PACKAGES_REPORT,
} from "./types"

export const saveDamagedPackage = (orderId, photo) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_DAMAGED_PACKAGE_REQUEST,
    })
    let formData = new FormData()
    formData.append("photo", photo)
    axios
      .post(api.saveDamagedPackage(orderId), formData)
      .then((res) => {
        dispatch({
          type: SAVE_DAMAGED_PACKAGE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          dispatch({
            type: SAVE_DAMAGED_PACKAGE_CONFLICT,
            payload: err,
          })
        } else {
          dispatch({
            type: SAVE_DAMAGED_PACKAGE_FAIL,
            payload: err,
          })
        }
      })
  }
}

export const clearDamagedPackage = () => {
  return {
    type: CLEAR_DAMAGED_PACKAGE,
  }
}

export const createDamagedPackagesReport = () => {
  return async (dispatch) => {
    dispatch({ type: CREATE_DAMAGED_PACKAGES_REPORT_REQUEST })

    try {
      const response = await axios.post(api.createDamagedPackagesReport, null, {
        responseType: "blob",
      })
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = "DamagedPackagesReport.xlsx"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      dispatch({
        type: CREATE_DAMAGED_PACKAGES_REPORT_SUCCESS,
      })
    } catch (err) {
      if (err.response?.status === 404) {
        dispatch({
          type: CREATE_DAMAGED_PACKAGES_REPORT_NOT_FOUND,
          payload: err,
        })
      } else {
        dispatch({
          type: CREATE_DAMAGED_PACKAGES_REPORT_FAIL,
          payload: err,
        })
      }
    }
  }
}

export const getLastReport = () => {
  return async (dispatch) => {
    dispatch({ type: CREATE_DAMAGED_PACKAGES_REPORT_REQUEST })

    try {
      const response = await axios.post(api.getLastReport, null, {
        responseType: "blob",
      })
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = "DamagedPackagesReport.xlsx"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      dispatch({
        type: CREATE_DAMAGED_PACKAGES_REPORT_SUCCESS,
      })
    } catch (err) {
      if (err.response?.status === 404) {
        dispatch({
          type: CREATE_DAMAGED_PACKAGES_REPORT_NOT_FOUND,
          payload: err,
        })
      } else {
        dispatch({
          type: CREATE_DAMAGED_PACKAGES_REPORT_FAIL,
          payload: err,
        })
      }
    }
  }
}

export const clearCreateDamagedPackagesReport = () => {
  return {
    type: CLEAR_CREATE_DAMAGED_PACKAGES_REPORT,
  }
}
