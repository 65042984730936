import React from "react"
import { BlueButton } from "../../../../../../common"
import "./style.scss"

const ReceptionOrderItem = ({
  item,
  itemMap,
  gravity,
  isLast,
  onEditClick,
  role,
}) => {
  const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

  return (
    <div className="reception-order-item">
      <div className={itemClassName}>
        {itemMap.map((itmMap, index) => {
          const flex = gravity[`${index}`] || 1

          switch (itmMap) {
            case "orderNumber":
              const orderNumber = item[itmMap] === 0 ? "" : item[itmMap]
              return (
                <div style={{ flex }} key={index}>
                  {orderNumber}
                </div>
              )

            case "editBtn":
              return (
                <div style={{ flex }} key={index}>
                  <BlueButton
                    extraClass="btn-redirect"
                    onClick={() => onEditClick(item?.orderId)}
                  >
                    עריכה
                  </BlueButton>
                </div>
              )
            default:
              let itemContent = item[itmMap]
              return (
                <div style={{ flex }} key={index}>
                  {itemContent}
                </div>
              )
          }
        })}
      </div>
    </div>
  )
}

export default ReceptionOrderItem
