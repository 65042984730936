import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFormatedDate } from "../../../../utils"
import Select from "react-select"
import {
  fetchStationWithSnapshots,
  searchStationSnapshots,
  clearSearchSnapshots,
} from "../../../../actions"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import { BlueButton, Spinner } from "../../../common"
import "./StataionSnapshotsScreen.scss"
import { fetchIfEmpty } from "../../../../utils"

const StationSnapshotsScreen = () => {
  const dispatch = useDispatch()
  const {
    stationsWithSnapshots,
    searchStationSnapshotsSuccess,
    stationSnapshots,
    isSnapshotsLoading,
  } = useSelector((state) => state.stationsList)
  const [showError, setShowError] = useState(null)
  const [selectedStation, setSelectedStation] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [fromTime, setFromTime] = useState(new Date())
  const [toTime, setToTime] = useState(new Date())
  const [selectedImage, setSelectedImage] = useState(null)
  const [rotation, setRotation] = useState(0)

  useEffect(() => {
    fetchIfEmpty(stationsWithSnapshots, () =>
      dispatch(fetchStationWithSnapshots())
    )
    return () => {
      dispatch(clearSearchSnapshots())
    }
  }, [dispatch])

  const handleSearch = () => {
    if (selectedStation == undefined || selectedStation?.stationId == null) {
      setShowError("יש לבחור עמדה.")
      return
    }
    if (!selectedDate || !fromTime || !toTime) {
      setShowError("יש לבחור תאריך, זמן התחלה וזמן סיום.")
      return
    }
    const startDateTime = new Date(selectedDate)
    startDateTime.setHours(fromTime.getHours(), fromTime.getMinutes())

    const endDateTime = new Date(selectedDate)
    endDateTime.setHours(toTime.getHours(), toTime.getMinutes())

    if (endDateTime <= startDateTime) {
      setShowError("זמן הסיום חייב להיות מאוחר יותר מזמן ההתחלה.")
      return
    }
    setShowError(null)
    dispatch(
      searchStationSnapshots(
        selectedStation?.stationId,
        startDateTime,
        endDateTime
      )
    )
  }

  const handleDownload = (imageSrc, fileName) => {
    const link = document.createElement("a")
    link.href = imageSrc
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleRotate = (e) => {
    e.stopPropagation()
    setRotation((prevRotation) => prevRotation + 90)
  }

  return (
    <div className="StationSnapshotsScreen">
      <h1>צילומים מעמדות</h1>

      <div className="search-container">
        <Select
          className="station-selector"
          value={selectedStation}
          options={stationsWithSnapshots || []}
          onChange={setSelectedStation}
          placeholder="בחר עמדה"
        />

        <div className="date-time-container">
          <DatePicker
            selected={selectedDate}
            onChange={setSelectedDate}
            dateFormat="yyyy-MM-dd"
            className="date-picker"
          />

          <DatePicker
            selected={fromTime}
            onChange={setFromTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            className="time-picker"
          />
          <DatePicker
            selected={toTime}
            onChange={setToTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            className="time-picker"
          />
        </div>
        <BlueButton extraClass="btn-search-snapshots" onClick={handleSearch}>
          חפש
        </BlueButton>
      </div>

      {showError && <p className="error-message">{showError}</p>}
      <div className="snapshots-grid">
        {isSnapshotsLoading && <Spinner zoom={1} />}
        {searchStationSnapshotsSuccess && stationSnapshots?.length == 0 && (
          <h2>לא נמצאו נתונים.</h2>
        )}
        {stationSnapshots?.length > 0 &&
          stationSnapshots.map((photo) => (
            <div key={photo.imageName} className="photo-card">
              <img
                src={photo.imageData}
                alt={photo.imageName}
                className="station-photo"
                onClick={() => setSelectedImage(photo?.imageData)}
              />
              <div className="photo-details">
                <div>
                  <strong>שם:</strong> {photo.firstName} {photo.lastName}
                </div>
                <div>
                  <strong>טלפון:</strong> {photo.mobilePhone}
                </div>
                <div>
                  <strong>תאריך:</strong> {getFormatedDate(photo.creationDate)}
                </div>
              </div>
              <BlueButton
                extraClass="download-button"
                onClick={() => handleDownload(photo.imageData, photo.imageName)}
              >
                <i className="fas fa-download"></i> הורד
              </BlueButton>
            </div>
          ))}

        {selectedImage && (
          <div className="modal" onClick={() => setSelectedImage(null)}>
            <BlueButton onClick={handleRotate} className="rotate-button">
              <i className="fa fa-repeat"></i>
            </BlueButton>

            <div
              className="modal-content"
              style={{
                transform: `rotate(${rotation}deg)`,
                transition: "0.3s ease",
              }}
            >
              <img src={selectedImage} alt="Full-size preview" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default StationSnapshotsScreen
