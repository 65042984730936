import axios from "axios"
import api from "../api"
import {
  GET_ORDER_STORAGE_STATUSES,
  GET_ORDER_STORAGE_STATUSES_FAIL,
  GET_STORAGE_SACK_NUMBERS_SUCSESS,
  GET_STORAGE_SACK_NUMBERS_FAIL,
  ON_CLOSE_STORAGE_SACK_REQUEST,
  ON_CLOSE_STORAGE_SACK_SUCCESS,
  CLEAR_CLOSE_STORAGE_SACK,
  ON_CLOSE_STORAGE_SACK_FAIL,
  ON_ORDER_IN_SACK_SUCCESS,
  ON_ORDER_IN_SACK_FAIL,
  ON_SEARCH_STORAGE_SACK_REQUEST,
  ON_SEARCH_STORAGE_SACK_SUCCESS,
  ON_SEARCH_STORAGE_SACK_FAIL,
  CLEAR_SEARCH_STORAGE_SACKS,
  ON_GET_STORAGE_SACK_ORDERS_SUCCESS,
  ON_GET_STORAGE_SACK_ORDERS_FAIL,
  ON_CLOSE_ALL_STORAGE_SACKS_REQUEST,
  ON_CLOSE_ALL_STORAGE_SACKS_SUCCESS,
  ON_CLOSE_ALL_STORAGE_SACKS_FAIL,
  CLEAR_CLOSE_ALL_STORAGE_SACKS,
  GET_SACK_SUMMARY_BY_STATION_SUCCESS,
  GET_SACK_SUMMARY_BY_STATION_FAIL,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const getOrderStorageStatuses = () => {
  return (dispatch) => {
    axios
      .get(api.getOrderStorageStatuses)
      .then((res) => {
        dispatch({
          type: GET_ORDER_STORAGE_STATUSES,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: GET_ORDER_STORAGE_STATUSES_FAIL,
          payload: err,
        })
      })
  }
}

export const getStorageSackIdsByRouteAreaCode = (areaCodeId) => {
  return (dispatch) => {
    axios
      .get(api.getStorageSackIdsByRouteAreaCode(areaCodeId))
      .then((res) => {
        dispatch({
          type: GET_STORAGE_SACK_NUMBERS_SUCSESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: GET_STORAGE_SACK_NUMBERS_FAIL,
          payload: err,
        })
      })
  }
}

export const createAndcloseStorageSack = (storageSack) => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_STORAGE_SACK_REQUEST,
    })
    axios
      .post(api.createAndcloseStorageSack, storageSack)
      .then((res) => {
        dispatch({
          type: ON_CLOSE_STORAGE_SACK_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_CLOSE_STORAGE_SACK_FAIL,
          payload: err,
        })
      })
  }
}

export const closeAllSacks = (areaCodeId) => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_ALL_STORAGE_SACKS_REQUEST,
    })
    axios
      .post(api.closeAllSacks(areaCodeId))
      .then((res) => {
        dispatch({
          type: ON_CLOSE_ALL_STORAGE_SACKS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_CLOSE_ALL_STORAGE_SACKS_FAIL,
          payload: err,
        })
      })
  }
}

export const clearCloseAllSacks = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CLOSE_ALL_STORAGE_SACKS,
    })
  }
}

export const clearCloseSack = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CLOSE_STORAGE_SACK,
    })
  }
}

export const getOrdersInSack = (areaCodeId, sackNumber) => {
  return (dispatch) => {
    axios
      .get(api.getOrdersInSack(areaCodeId, sackNumber))
      .then((res) => {
        dispatch({
          type: ON_ORDER_IN_SACK_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_ORDER_IN_SACK_FAIL,
          payload: err,
        })
      })
  }
}

export const searchStorageSackByOrder = (orderSearchTerm) => {
  return (dispatch) => {
    axios
      .get(api.searchStorageSackByOrder(orderSearchTerm))
      .then((res) => {
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const searchStorageSackByBarcode = (barcode) => {
  return (dispatch) => {
    dispatch({
      type: ON_SEARCH_STORAGE_SACK_REQUEST,
    })
    axios
      .get(api.searchStorageSackByBarcode(barcode))
      .then((res) => {
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const searchStorageSacksByDate = (fromDate, toDate) => {
  const formattedFromDate = fromDate.toISOString()
  const formattedToDate = toDate.toISOString()

  return (dispatch) => {
    dispatch({
      type: ON_SEARCH_STORAGE_SACK_REQUEST,
    })
    axios
      .get(api.searchStorageSacksByDate(formattedFromDate, formattedToDate))
      .then((res) => {
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearSearchStorageSacks = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_STORAGE_SACKS,
    })
  }
}

export const getStorageSackOrdersBySackId = (storageSackId) => {
  return (dispatch) => {
    axios
      .get(api.getStorageSackOrdersBySackId(storageSackId))
      .then((res) => {
        dispatch({
          type: ON_GET_STORAGE_SACK_ORDERS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_STORAGE_SACK_ORDERS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getSackSummaryByStation = () => {
  return (dispatch) => {
    axios
      .get(api.getSackSummaryByStation)
      .then((res) => {
        dispatch({
          type: GET_SACK_SUMMARY_BY_STATION_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_SACK_SUMMARY_BY_STATION_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const searchNotScannedByDriverList = () => {
  return (dispatch) => {
    dispatch({
      type: ON_SEARCH_STORAGE_SACK_REQUEST,
    })
    axios
      .get(api.searchNotScannedByDriverList)
      .then((res) => {
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_STORAGE_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}
