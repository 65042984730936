import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import StationsFilterer from "../../../reduxified/StationsFilterer"
import StationsList from "./StationsList"
import { Pagination, BlueButton } from "../../../common"
import {
  fetchStations,
  fetchPriceListSelectOptions,
  selectStation,
  showStationLoading,
  setLockersStation,
  setLockersHistoryStation,
  localSearchStationsSetPage,
  setLockersInfraSensorStation,
} from "../../../../actions"
import exportFromJSON from "export-from-json"
import "./style.scss"

const StationsPage = ({ history, match }) => {
  const dispatch = useDispatch()
  const [pagedList, setPagedList] = useState([])

  const {
    isLoading,
    stationsFilteredListArr,
    pageNum,
    routeAreaCodeArray,
    unauthorized,
  } = useSelector(({ stationsSection, errors, routeAreaCode }) => ({
    isLoading: stationsSection.isLoading,
    stationsFilteredListArr:
      stationsSection.stationsLocalSearch.stationsFilteredListArr,
    pageNum: stationsSection.stationsLocalSearch.page,
    routeAreaCodeArray: routeAreaCode.routeAreaCodeArray,
    unauthorized: errors.unauthorized,
  }))

  useEffect(() => {
    if (unauthorized) {
      history.push("/")
      return
    }
    dispatch(showStationLoading())
    dispatch(fetchStations())
    dispatch(fetchPriceListSelectOptions())
  }, [dispatch, unauthorized, history])

  const resetCurrentPage = () => {
    dispatch(localSearchStationsSetPage(1))
    setPagedList([])
  }

  const handleEdit = (itm) => {
    history.push(`${match.path}/edit`)
    dispatch(selectStation(itm))
  }

  const handleSendSms = (itm) => {
    history.push(`${match.path}/sendSMS`)
    dispatch(selectStation(itm))
  }

  const handleCells = (itm) => {
    dispatch(setLockersStation(itm.stationId))
    history.push("/LockersScreen/lockers")
  }

  const handleLockersStatus = (itm) => {
    dispatch(selectStation(itm))
    history.push("/LockersScreen/status")
  }

  const handleLockersHistory = (itm) => {
    dispatch(setLockersHistoryStation(itm.stationId))
    history.push("/LockersScreen/history")
  }

  const handleLockersSensorInfra = (itm) => {
    dispatch(setLockersInfraSensorStation(itm.stationId))
    history.push("/LockersScreen/lockersSensorInfra")
  }

  const updatedPagedList = pagedList.map((itm) => ({
    ...itm,
    routeAreaCode: itm.routeAreaCodeId
      ? routeAreaCodeArray[itm.routeAreaCodeId]
      : undefined,
  }))

  const exportStationToExcel = () => {
    if (!updatedPagedList || updatedPagedList.length === 0) {
      console.warn("No data to export")
      return
    }

    const headers = [
      "מחובר",
      "מזהה",
      "שם סניף",
      "כתובת",
      "מספר לוקרים",
      "תאים ריקים",
      "תאים שבורים",
      "גרסה $",
      "מסלול הפצה",
      "קוד אזור",
    ]

    const data = updatedPagedList.map((station) => [
      station.isAlive == 1 ? "כן" : "לא",
      station.stationNumber,
      station.branchName,
      station.branchAddress,
      station.lockersNumber,
      station.emptyLockersNum,
      station.brokenLockersNum,
      station.stationPaymentVersion,
      station.routeName,
      station.routeAreaCode,
    ])

    data.unshift(headers)
    const fileName = "stations"
    const exportType = exportFromJSON.types.csv
    exportFromJSON({
      data,
      fileName,
      exportType,
      withBOM: true,
    })
  }

  return (
    <div className="stations-page-wrapper">
      <StationsFilterer resetCurrentPage={resetCurrentPage} />

      <div className="station-page" key={updatedPagedList.length}>
        <StationsList
          items={updatedPagedList}
          onItemEditBtnClick={handleEdit}
          onSendSmsBtnClick={handleSendSms}
          onLockersStatusBtnClick={handleLockersStatus}
          onCellsBtnClick={handleCells}
          onLockersHistoryBtnClick={handleLockersHistory}
          onLockersSensorInfraBtnClick={handleLockersSensorInfra}
          isLoading={isLoading}
        />
        <Pagination
          fullList={stationsFilteredListArr}
          totalPerPage={50}
          onSubListReady={(newPagedList, newPageNum) => {
            dispatch(localSearchStationsSetPage(newPageNum))
            setPagedList(newPagedList)
          }}
          pageNum={pageNum}
        />
      </div>
      <div className="export-wrapper">
        <BlueButton
          className="blbtn-wrapper base-btn"
          onClick={() => exportStationToExcel()}
        >
          יצאו לקובץ Excel
        </BlueButton>
      </div>
    </div>
  )
}

export default withRouter(StationsPage)
