import React, { useEffect, useRef, useState } from "react"
import { Route, Link } from "react-router-dom"
import SacksHistory from "./SacksPage/SacksHistory/SacksHistory"
import DeliveryOrderSacksList from "./SacksPage"
import "./SacksScreen.scss"

const SacksScreen = (props) => {
  return (
    <div className="SacksScreen">
      <nav className="section-menu">
        <ul>
          <li className="section-menu-item">
            <Link to={`${props.match.url}`}> סריקת תעודות</Link>
          </li>
          <li className="section-menu-item">
            <Link to={`${props.match.url}/SackHistory`}>היסטוריה</Link>
          </li>
        </ul>
      </nav>
      <main className="section-body">
        <Route
          exact
          path={`${props.match.path}`}
          component={DeliveryOrderSacksList}
        />
        <Route
          path={`${props.match.path}sackHistory`}
          component={SacksHistory}
        />
      </main>
    </div>
  )
}

export default SacksScreen
