import React from "react"
import { useSelector } from "react-redux"
import { getFormatedDate } from "../../../../utils"
import Select from "react-select"
import "./style.scss"
const StationsVoltage = ({
  onSelectStationOnVoltage,
  defaultValueVoltageSelect,
  isMobile,
}) => {
  const { stationsVoltage, stationsVoltageSelect } = useSelector(
    (state) => state.stationsList
  )

  const selectItems = [
    { value: 0, label: "כול העמדות" },
    ...stationsVoltageSelect.map(
      ({ stationId, stationNumber, stationName }) => ({
        value: stationNumber,
        stationId: stationId,
        label: `${stationNumber} - ${stationName}`,
      })
    ),
  ]

  const filteredStationsVoltage =
    defaultValueVoltageSelect && defaultValueVoltageSelect.value !== 0
      ? stationsVoltage.filter(
          (itm) => itm.stationNumber === defaultValueVoltageSelect.value
        )
      : stationsVoltage

  return (
    <div className="stations-voltage-wrapper">
      <h3>
        <div className="down-count">
          {stationsVoltageSelect ? stationsVoltageSelect.length : ""}
        </div>
        מתח תחנות BLE
        <div className="select-wrapper">
          <div className="select-wrapper-inner">
            <Select
              classNamePrefix="autocomplete-select"
              options={selectItems}
              value={defaultValueVoltageSelect}
              onChange={
                (itm) => onSelectStationOnVoltage(itm) // Update on selection change
              }
            />
          </div>
        </div>
      </h3>
      <div className="stations-voltage-list">
        {isMobile ? null : (
          <div className="station-item title">
            <div className="column number">מספר</div>
            <div className="column name">שם עמדה</div>
            <div className="column voltage">מתח</div>
            <div className="column before-time">תאריך</div>
          </div>
        )}
        {filteredStationsVoltage.map((itm) => {
          const dateTime = getFormatedDate(itm.createdDate)
          return (
            <div
              className="station-item"
              key={itm.stationId}
              key-data={itm.stationId}
            >
              <div className="column number">{itm.stationNumber}</div>
              <div className="column name">{itm.stationName}</div>
              <div className="column voltage">{itm.voltage}</div>
              <div className="column before-time">{dateTime}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StationsVoltage
