const api = {}

const HOST_PORT = "3001"

const BASE_URL = `http://localhost:${HOST_PORT}` //node_js service

api.getStationId = `${BASE_URL}/getStationId`

api.getStationConfiguration = `${BASE_URL}/getStationConfiguration`

//api.printPinkLabel = `${BASE_URL}/printLabel`;

api.printHydroLabel = `${BASE_URL}/printHydroLabel`

api.printWhiteReceipt = `${BASE_URL}/printReceipt`

api.printSackSticker = `${BASE_URL}/printSack`

api.printStorageSackSticker = `${BASE_URL}/printStorageSack`

api.printStorageSackStickersList = `${BASE_URL}/printStorageSacksList`

export default api
