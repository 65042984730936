import {
  SAVE_DAMAGED_PACKAGE_REQUEST,
  SAVE_DAMAGED_PACKAGE_SUCCESS,
  SAVE_DAMAGED_PACKAGE_FAIL,
  SAVE_DAMAGED_PACKAGE_CONFLICT,
  CLEAR_DAMAGED_PACKAGE,
  CREATE_DAMAGED_PACKAGES_REPORT_REQUEST,
  CREATE_DAMAGED_PACKAGES_REPORT_SUCCESS,
  CREATE_DAMAGED_PACKAGES_REPORT_FAIL,
  CREATE_DAMAGED_PACKAGES_REPORT_NOT_FOUND,
  CLEAR_CREATE_DAMAGED_PACKAGES_REPORT,
} from "../actions/types"

const INIT_STATE = {
  isLoading: false,
  saveDamagedPackageSuccess: false,
  saveDamagedPackageFail: false,
  saveDamagedPackageConflict: false,
  damagedPackagesReportSuccess: false,
  damagedPackagesReportFail: false,
  damagedPackagesReport: null,
  damagedPackagesReportNotFound: false,
  isReportLoading: false,
}

const DamagedPackageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_DAMAGED_PACKAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        saveDamagedPackageSuccess: false,
        saveDamagedPackageFail: false,
      }
    case SAVE_DAMAGED_PACKAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveDamagedPackageSuccess: true,
        saveDamagedPackageFail: false,
      }
    case SAVE_DAMAGED_PACKAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        saveDamagedPackageSuccess: false,
        saveDamagedPackageFail: true,
      }
    case SAVE_DAMAGED_PACKAGE_CONFLICT:
      return {
        ...state,
        isLoading: false,
        saveDamagedPackageSuccess: false,
        saveDamagedPackageFail: false,
        saveDamagedPackageConflict: true,
      }
    case CLEAR_DAMAGED_PACKAGE:
      return {
        ...state,
        isLoading: false,
        saveDamagedPackageSuccess: false,
        saveDamagedPackageFail: false,
        saveDamagedPackageConflict: false,
      }
    case CREATE_DAMAGED_PACKAGES_REPORT_REQUEST:
      return {
        ...state,
        isReportLoading: true,
        damagedPackagesReportSuccess: false,
        damagedPackagesReportFail: false,
      }
    case CREATE_DAMAGED_PACKAGES_REPORT_SUCCESS:
      return {
        ...state,
        isReportLoading: false,
        damagedPackagesReportSuccess: true,
        damagedPackagesReportFail: false,
        damagedPackagesReport: action.payload,
      }
    case CREATE_DAMAGED_PACKAGES_REPORT_FAIL:
      return {
        ...state,
        isReportLoading: false,
        damagedPackagesReportSuccess: false,
        damagedPackagesReportFail: true,
      }
    case CREATE_DAMAGED_PACKAGES_REPORT_NOT_FOUND:
      return {
        ...state,
        isReportLoading: false,
        damagedPackagesReportSuccess: false,
        damagedPackagesReportFail: false,
        damagedPackagesReportNotFound: true,
      }
    case CLEAR_CREATE_DAMAGED_PACKAGES_REPORT:
      return {
        ...state,
        isReportLoading: false,
        damagedPackagesReportSuccess: false,
        damagedPackagesReportFail: false,
        damagedPackagesReportNotFound: false,
      }
    default:
      return state
  }
}

export default DamagedPackageReducer
