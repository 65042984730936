import React, { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch, useLocation } from "react-redux"
import { BlueButton, Spinner } from "../../../common"
import {
  fetchStationPhotos,
  uploadStationPhoto,
  deleteStationPhoto,
  clearStationPhotos,
} from "../../../../actions/StationPhotosAction"
import YesNoModal from "../../../Modals/YesNoModal"
import "./StationPhotos.scss"

export default function StationPhotos(props) {
  const dispatch = useDispatch()
  const { logedUserData } = useSelector((state) => state.login)

  const {
    isGetPhotosLoading,
    stationPhotosList,
    stationPhotoUploadedSuccess,
    stationPhotoDeleteSuccess,
    getStationPhotosFail,
    stationPhotoDeleteFail,
  } = useSelector((state) => state.stationPhotos)

  const [stationId, setStationId] = useState(null)
  const [stationNumber, setStationNumber] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [imageToDelete, setImageToDelete] = useState(null)
  const [dragOver, setDragOver] = useState(false)

  useEffect(() => {
    if (!logedUserData) {
      props.history.push("/")
      return
    }

    const queryParams = new URLSearchParams(props.location.search)
    const stationId = queryParams.get("stationId")
    const stationNumber = queryParams.get("stationNumber")

    if (stationId) {
      setStationId(stationId)
      setStationNumber(stationNumber)
      dispatch(fetchStationPhotos(stationId))
    }

    return () => dispatch(clearStationPhotos())
  }, [dispatch])

  useEffect(() => {
    if (stationPhotoUploadedSuccess || stationPhotoDeleteSuccess) {
      dispatch(fetchStationPhotos(stationId))
    }
  }, [
    dispatch,
    stationPhotoUploadedSuccess,
    stationPhotoDeleteSuccess,
    stationId,
  ])

  useEffect(() => {
    if (stationPhotoDeleteFail || getStationPhotosFail) {
      dispatch(clearStationPhotos())
    }
  }, [dispatch, stationPhotoDeleteFail, getStationPhotosFail])

  const handleImageUpload = useCallback(
    (event) => {
      const file = event.target.files[0]
      if (file && stationId) {
        dispatch(uploadStationPhoto(stationId, file))
      }
    },
    [dispatch, stationId]
  )

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault()
      setDragOver(false)
      const file = event.dataTransfer.files[0]
      if (file && stationId) {
        dispatch(uploadStationPhoto(stationId, file))
      }
    },
    [dispatch, stationId]
  )

  const confirmDeletePhoto = useCallback((fileName) => {
    setShowDeleteConfirm(true)
    setImageToDelete(fileName)
  }, [])

  const handleDownload = (imageSrc, fileName) => {
    const link = document.createElement("a")
    link.href = imageSrc
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className="station-photos-container">
      <h1> תמונות של תחנה {stationNumber} </h1>

      <div
        className={`upload-area ${dragOver ? "drag-over" : ""}`}
        onDrop={handleDrop}
        onDragOver={(e) => {
          e.preventDefault()
          setDragOver(true)
        }}
        onDragLeave={() => setDragOver(false)}
      >
        <input
          type="file"
          accept="image/*"
          id="upload"
          className="image-upload-input"
          onChange={handleImageUpload}
        />
        <label htmlFor="upload" className="image-upload-button">
          <i className="fas fa-upload"></i> גרור ושחרר או לחץ כדי להעלות תמונה
        </label>
      </div>

      <div className="photos-grid">
        {isGetPhotosLoading && <Spinner zoom={1} />}
        {stationPhotosList?.length > 0
          ? stationPhotosList.map((photo) => {
              const imageSrc = `data:${photo.contentType};base64,${photo.fileData}`
              const isDeleting = imageToDelete === photo.fileName

              return (
                <div key={photo.fileName} className="photo-item">
                  {isDeleting ? (
                    <Spinner zoom={1} />
                  ) : (
                    <img
                      src={imageSrc}
                      alt={photo.fileName}
                      className="station-photo"
                      onClick={() => setSelectedImage(imageSrc)}
                    />
                  )}
                  <div className="photo-actions">
                    <BlueButton
                      extraClass="download-button"
                      onClick={() => handleDownload(imageSrc, photo.fileName)}
                    >
                      <i className="fas fa-download"></i>
                    </BlueButton>
                    {!isDeleting && (
                      <BlueButton
                        extraClass="delete-button"
                        onClick={() => confirmDeletePhoto(photo.fileName)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </BlueButton>
                    )}
                  </div>
                </div>
              )
            })
          : !isGetPhotosLoading && (
              <p className="no-photos-message">עדיין לא הועלו תמונות</p>
            )}

        {selectedImage && (
          <div className="modal" onClick={() => setSelectedImage(null)}>
            <div className="modal-content">
              <img src={selectedImage} alt="Full-size preview" />
            </div>
          </div>
        )}
      </div>

      <YesNoModal
        show={showDeleteConfirm}
        title="תמונות תחנה"
        text="האם ברצונך למחוק תמונה?"
        onYesClick={() => {
          if (stationId && imageToDelete) {
            dispatch(deleteStationPhoto(stationId, imageToDelete))
          }
          setShowDeleteConfirm(false)
          setImageToDelete(null)
        }}
        onNoClick={() => {
          setShowDeleteConfirm(false)
          setImageToDelete(null)
        }}
      />
    </div>
  )
}
