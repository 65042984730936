import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { BlueButton, Spinner } from "../../../../common"
import DatePicker from "react-datepicker"

import {
  searchStorageSackByBarcode,
  searchStorageSackByOrder,
  searchStorageSacksByDate,
  searchNotScannedByDriverList,
  clearSearchStorageSacks,
  fetchOrderTypes,
  getStorageSackOrdersBySackId,
  clearOrdersSacks,
  getOrderStorageStatuses,
  printStorageSackSticker,
  fetchAllOrderStatuses,
  fetchAllStationData,
  fetchRoutes,
} from "../../../../../actions"
import CertificateOrdersTable from "../../ShippingCertificate/CertificateOrdersTable/CertificateOrdersTable.js"
import { getFormatedDate } from "../../../../../utils/FormatedDate"
import { fetchIfEmpty } from "../../../../../utils"
import "./style.scss"

const SearchStorageSackPage = () => {
  const dispatch = useDispatch()
  const barcodeSearchTermRef = useRef("")
  const orderSearchTermRef = useRef("")
  const [selectedDate, setSelectedDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [showError, setShowError] = useState(null)
  const [isNotScannedByDriver, setIsNotScannedByDriver] = useState(false)
  const [selectedStation, setSelectedStation] = useState(null)
  const [selectedRoute, setSelectedRoute] = useState(null)
  const [searchStorageSackResultFiltered, setSearchStorageSackResultFiltered] =
    useState([])

  const {
    searchStorageSackSuccess,
    searchStorageSackResult,
    searchSackOrdersSuccess,
    searchSackOrdersResult,
    orderTypes,
    orderStorageStatuses,
    orderStatusesObj,
    accountTypes,
    stationsItemSelectList,
    routes,
    isSearchSackLoading,
  } = useSelector((state) => ({
    searchStorageSackSuccess: state.orderStorageSack.searchStorageSackSuccess,
    searchStorageSackResult: state.orderStorageSack.searchStorageSackResult,
    isSearchSackLoading: state.orderStorageSack.isSearchSackLoading,
    searchSackOrdersSuccess: state.orderStorageSack.searchSackOrdersSuccess,
    searchSackOrdersResult: state.orderStorageSack.searchSackOrdersResult,
    orderTypes: state.selectOptions.orderTypes,
    orderStorageStatuses: state.selectOptions.orderStorageStatuses,
    orderStatusesObj: state.ordersStatuses.orderStatusesObj,
    accountTypes: state.selectOptions.accountTypes,
    stationsItemSelectList: state.stationsList.stationsItemSelectList,
    routes: state.routes.routes,
  }))

  useEffect(() => {
    fetchIfEmpty(orderTypes, dispatch(fetchOrderTypes()))
    fetchIfEmpty(orderStorageStatuses, dispatch(getOrderStorageStatuses()))
    fetchIfEmpty(orderStatusesObj, dispatch(fetchAllOrderStatuses))
    fetchIfEmpty(stationsItemSelectList, () => dispatch(fetchAllStationData()))
    fetchIfEmpty(routes, () => dispatch(fetchRoutes()))
    return () => {
      dispatch(clearOrdersSacks())
      dispatch(clearSearchStorageSacks())
    }
  }, [dispatch, orderStatusesObj])

  useEffect(() => {
    if (searchStorageSackResult && !Array.isArray(searchStorageSackResult)) {
      dispatch(getStorageSackOrdersBySackId(searchStorageSackResult.id))
    }
    if (Array.isArray(searchStorageSackResult)) {
      setSearchStorageSackResultFiltered(searchStorageSackResult)
    }
  }, [dispatch, searchStorageSackResult])

  useEffect(() => {
    if (
      searchStorageSackSuccess &&
      (searchStorageSackResult === "" || searchStorageSackResult?.length === 0)
    ) {
      setShowError("לא נמצאו תוצאות התואמות לחיפוש.")
    }
  }, [searchStorageSackSuccess])

  const handleBarcodeSearch = () => {
    if (barcodeSearchTermRef.current.value === "") return
    setShowError(null)
    dispatch(clearSearchStorageSacks())
    dispatch(clearSearchStorageSacks())
    dispatch(searchStorageSackByBarcode(barcodeSearchTermRef.current.value))
    setIsNotScannedByDriver(false)
  }

  const handleOrderNumberSearch = () => {
    if (orderSearchTermRef.current.value === "") return
    setShowError(null)
    dispatch(clearSearchStorageSacks())
    dispatch(clearSearchStorageSacks())
    dispatch(searchStorageSackByOrder(orderSearchTermRef.current.value))
    setIsNotScannedByDriver(false)
  }

  const handleNotScannedByDriverSearch = () => {
    setShowError(null)
    dispatch(clearSearchStorageSacks())
    dispatch(clearSearchStorageSacks())
    dispatch(searchNotScannedByDriverList())
    setIsNotScannedByDriver(true)
  }

  const searchByDate = () => {
    if (!selectedDate || !startTime || !endTime) {
      setShowError("יש לבחור תאריך, זמן התחלה וזמן סיום.")
      return
    }
    const startDateTime = new Date(selectedDate)
    startDateTime.setHours(startTime.getHours(), startTime.getMinutes())

    const endDateTime = new Date(selectedDate)
    endDateTime.setHours(endTime.getHours(), endTime.getMinutes())

    if (endDateTime <= startDateTime) {
      setShowError("זמן הסיום חייב להיות מאוחר יותר מזמן ההתחלה.")
      return
    }
    setShowError(null)
    dispatch(searchStorageSacksByDate(startDateTime, endDateTime))
    dispatch(clearSearchStorageSacks())
    setIsNotScannedByDriver(false)
  }

  const getStorageStatusName = (statusId) => {
    return orderStorageStatuses.find((obj) => obj.id === statusId)
      ?.statusName_he
  }

  const printSackSticker = () => {
    const sackData = {
      sackNumber: searchStorageSackResult?.sackNumber,
      barcode: searchStorageSackResult?.barcode,
      ordersCount: searchStorageSackResult?.ordersTotal,
      sackClosedDate: getFormatedDate(searchStorageSackResult?.createdDate),
      stationId: searchSackOrdersResult[0]?.stationId,
      stationName: searchSackOrdersResult[0]?.branchName,
      stationNumber: searchSackOrdersResult[0]?.stationNumber,
      routeName: searchSackOrdersResult[0]?.routeName,
      areaCodeName: searchSackOrdersResult[0]?.routeAreaCode,
      isVip: searchStorageSackResult?.isVip,
    }
    dispatch(printStorageSackSticker(sackData))
  }

  const filterNotScannedSacksByStation = (stationId) => {
    setSelectedStation(stationId)
    setSelectedRoute(null)
    const filteredResults = searchStorageSackResult.filter(
      (sack) => sack.stationId === stationId
    )
    setSearchStorageSackResultFiltered(filteredResults)
  }

  const filterNotScannedSacksByRoute = (routeId) => {
    setSelectedRoute(routeId)
    setSelectedStation(null)
    const filteredResults = searchStorageSackResult.filter(
      (sack) => sack.station?.route?.id === routeId
    )
    setSearchStorageSackResultFiltered(filteredResults)
  }

  return (
    <div className="StorageSacksHistory">
      <h1>חיפוש שקי מחסן</h1>
      <div className="search-sacks no-print">
        <div className="search-row">
          <div className="search-input">
            <input
              type="text"
              placeholder="חפש שק לפי ברקוד"
              ref={barcodeSearchTermRef}
              className="input-field"
            />
            <BlueButton
              extraClass="search-button"
              onClick={handleBarcodeSearch}
            >
              חפש
            </BlueButton>
          </div>
          <div className="search-input">
            <input
              placeholder="חפש שק לפי הזמנה"
              type="text"
              ref={orderSearchTermRef}
              className="input-field"
            />
            <BlueButton
              extraClass="search-button"
              disabled={!orderSearchTermRef.current}
              onClick={handleOrderNumberSearch}
            >
              חפש
            </BlueButton>
          </div>
          <div className="datepicker-container">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              placeholderText="תאריך"
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-end"
              maxDate={new Date()}
              showTimeSelect={false}
              className="date-picker"
            />
            <DatePicker
              selected={startTime}
              onChange={(time) => setStartTime(time)}
              placeholderText="זמן התחלה"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              className="time-picker"
            />
            <DatePicker
              selected={endTime}
              onChange={(time) => setEndTime(time)}
              placeholderText="זמן סיום"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              className="time-picker"
            />
            <BlueButton extraClass="search-button" onClick={searchByDate}>
              חפש
            </BlueButton>
          </div>

          <div className="btn-print-wrapper">
            <BlueButton
              extraClass="search-button"
              disabled={!orderSearchTermRef.current}
              onClick={handleNotScannedByDriverSearch}
            >
              חפש שקים שלא נסרקו
            </BlueButton>
            <BlueButton
              extraClass="btn-print"
              disabled={!searchStorageSackSuccess}
              onClick={printSackSticker}
            >
              הדפס ברקוד
            </BlueButton>
          </div>
        </div>
      </div>
      {showError ? <div className="error">{showError}</div> : null}
      {isSearchSackLoading && <Spinner zoom={0.4} />}
      {searchStorageSackResult &&
        searchStorageSackResultFiltered?.length > 0 && (
          <div className="search-results">
            <div className="sacks-found">
              {isNotScannedByDriver ? (
                <h3>שקים שלא נסרקו על ידי נהג</h3>
              ) : (
                <h3>פרטי שק</h3>
              )}
              {isNotScannedByDriver && (
                <div className="filters">
                  <Select
                    className="base-select"
                    placeholder="סנן לפי תחנה"
                    value={
                      stationsItemSelectList.find(
                        (station) => station.stationId === selectedStation
                      ) || null
                    }
                    options={stationsItemSelectList}
                    onChange={(e) =>
                      filterNotScannedSacksByStation(e.stationId)
                    }
                  />
                  <Select
                    className="base-select"
                    placeholder="סנן לפי מסלול"
                    value={
                      routes.find((route) => route.value === selectedRoute) ||
                      null
                    }
                    options={routes}
                    onChange={(e) => filterNotScannedSacksByRoute(e.value)}
                  />
                </div>
              )}

              <div className="result-table">
                <table>
                  <thead>
                    <tr>
                      <th>ברקוד</th>
                      <th>מספר שק</th>
                      <th>סטטוס</th>
                      <th>תאריך יצירה</th>
                      <th>מספר תחנה</th>
                      <th>מסלול</th>
                      {/* <th>תאריך עידכון</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {searchStorageSackSuccess &&
                    Array.isArray(searchStorageSackResult)
                      ? searchStorageSackResultFiltered.map((sack) => (
                          <tr key={sack.barcode}>
                            <td>{sack.barcode}</td>
                            <td>{sack.sackNumber}</td>
                            <td>
                              {getStorageStatusName(sack.orderStorageStatus)}
                            </td>
                            <td>{getFormatedDate(sack.createdDate)}</td>
                            <td>{sack.stationNumber}</td>
                            <td>{sack.routeName}</td>
                            {/* <td>{getFormatedDate(sack.updatedDate)}</td> */}
                          </tr>
                        ))
                      : searchStorageSackSuccess &&
                        searchStorageSackResult && (
                          <tr>
                            <td>{searchStorageSackResult.barcode}</td>
                            <td>{searchStorageSackResult.sackNumber}</td>
                            <td>
                              {getStorageStatusName(
                                searchStorageSackResult.orderStorageStatus
                              )}
                            </td>
                            <td>
                              {getFormatedDate(
                                searchStorageSackResult.createdDate
                              )}
                            </td>
                            <td>{searchStorageSackResult.stationNumber}</td>
                            <td>{searchStorageSackResult.routeName}</td>
                            {/* <td>
                            {getFormatedDate(
                              searchStorageSackResult.updatedDate
                            )}
                          </td> */}
                          </tr>
                        )}
                  </tbody>
                </table>
              </div>
            </div>
            {searchSackOrdersSuccess && (
              <div className="orders-table">
                <h3>רשימת הזמנות בשק</h3>
                <CertificateOrdersTable
                  ordersList={searchSackOrdersResult}
                  accountTypesList={accountTypes}
                  ordersStatusesList={orderStatusesObj}
                />
              </div>
            )}
          </div>
        )}
    </div>
  )
}

export default SearchStorageSackPage
