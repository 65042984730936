import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PersonalDetailsTab from "./PersonalDetailsTab"
import AcountDetailsTab from "./AcountDetailsTab"
import CommentsTab from "./CommentsTab"
import OthersTab from "./OthersTab"
import UserHistoryTab from "./UserHistoryTab"
import UserOrdersTab from "./UserOrdersTab"
import UserSubscriptionsModal from "../../../Modals/UserSubscriptionsModal"
import {
  fetchUserSubscriptions,
  blockCustomer,
  unblockCustomer,
  checkUserBlackList,
  fetchExistingUserDataForUpdate,
} from "../../../../actions"
import "./style.css"

const tabTitles = [
  "פרטים אישיים",
  "פרטים כלליים לחשבון",
  "שונות",
  "הערות",
  "היסטוריה",
  "הזמנות",
]

const UserTabNavigator = ({
  userData,
  userRoles,
  sortedStationsNames,
  countries,
  languages,
  userHistoryData,
  userOrdersData,
  onUserDataFieldUpdate,
  removeCCToken,
  showUpdateBtn,
  onUserHistoryPageClick,
  onOrderItemEditBtnClick,
  onUserOrdersPageClick,
}) => {
  const [focused, setFocused] = useState(0)
  const [showSubscriptionMessage, setShowSubscriptionMessage] = useState(false)
  const dispatch = useDispatch()

  const {
    isUserInBlackList,
    customerIsBlockedSuccess,
    customerIsUnBlockedSuccess,
  } = useSelector((state) => state.usersList)

  useEffect(() => {
    if (userData?.mobilePhone) {
      dispatch(checkUserBlackList(userData?.mobilePhone))
    }
  }, [dispatch, userData])

  useEffect(() => {
    if (customerIsBlockedSuccess || customerIsUnBlockedSuccess) {
      dispatch(checkUserBlackList(userData?.mobilePhone))
      dispatch(fetchExistingUserDataForUpdate(userData?.id))
    }
  }, [customerIsBlockedSuccess, customerIsUnBlockedSuccess])

  const onTabClick = (index) => {
    setFocused(index)
    showUpdateBtn(index !== 4 && index !== 5)
  }

  const getTab = () => {
    switch (focused) {
      case 0:
        return (
          <div className="tn-single-tab-wrapper">
            <PersonalDetailsTab
              languages={languages}
              countries={countries}
              userData={userData}
              onInputChange={onUserDataFieldUpdate}
              onOpenCancelSubscription={() => {
                setShowSubscriptionMessage(true)
                dispatch(fetchUserSubscriptions(userData.id))
              }}
              onBlockCustomer={() => dispatch(blockCustomer(userData.id))}
              onUnblockCustomer={() => dispatch(unblockCustomer(userData.id))}
            />
            <UserSubscriptionsModal
              show={showSubscriptionMessage}
              userData={userData}
              onClose={() => setShowSubscriptionMessage(false)}
            />
          </div>
        )
      case 1:
        return (
          <div className="tn-single-tab-wrapper">
            <AcountDetailsTab
              userRoles={userRoles}
              sortedStationsNames={sortedStationsNames}
              userData={userData}
              onInputChange={onUserDataFieldUpdate}
            />
          </div>
        )
      case 2:
        return (
          <div className="tn-single-tab-wrapper">
            <OthersTab
              userData={userData}
              onInputChange={onUserDataFieldUpdate}
              removeCCToken={removeCCToken}
            />
          </div>
        )
      case 3:
        return (
          <div className="tn-single-tab-wrapper">
            <CommentsTab
              userData={userData}
              onInputChange={onUserDataFieldUpdate}
            />
          </div>
        )
      case 4:
        return (
          <div className="tn-single-tab-wrapper user-history-tab">
            <UserHistoryTab
              userHistoryData={userHistoryData}
              onPageClick={onUserHistoryPageClick}
            />
          </div>
        )
      case 5:
        return (
          <div className="tn-single-tab-wrapper user-orders-tab">
            <UserOrdersTab
              userOrdersData={userOrdersData}
              onPageClick={onUserOrdersPageClick}
              onOrderItemEditBtnClick={onOrderItemEditBtnClick}
            />
          </div>
        )
      default:
        return <div />
    }
  }

  return (
    <div>
      <div className="tn-navigation-tab">
        {tabTitles.map((tabTitle, index) => (
          <div
            className={`tn-tabs-wrapper ${
              index === focused ? "tn-active-tab" : ""
            }`}
            key={index}
          >
            <div className="tn-tab" onClick={() => onTabClick(index)}>
              {tabTitle}
            </div>
          </div>
        ))}
      </div>
      {getTab()}
    </div>
  )
}

export default UserTabNavigator
