import React from "react"
import { withRouter } from "react-router-dom"
import "./Tile.scss"

const Tile = ({ title, link, history, extraClass = "", onClick }) => {
  const handleClick = (e) => {
    if (onClick) {
      e.stopPropagation()
      onClick(e)
    } else if (link) {
      history.push(link)
    }
  }

  return (
    <section className={`tile ${extraClass}`} onClick={handleClick}>
      <p className="tile-title">{title}</p>
    </section>
  )
}

export default withRouter(Tile)
