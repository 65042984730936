import React, { Component } from "react"
import { connect } from "react-redux"

import { WhiteFrame, BlueButton } from "../../common"
import {
  isMobileNumInvalid,
  isEmailInvalid,
  getStatusNameFromObj,
  getValEnumFromIndex,
} from "../../../utils"
import {
  createNewUser,
  updateUserData,
  onUserDataFieldUpdate,
  clearCurrentCreateEditUserData,
  getAllCountries,
  getLanguages,
  fetchAllStationData,
  fetchUserRoles,
  removeCCToken,
  clearUserHistory,
  setUserHistoryUser,
  onLoadingUserHistory,
  fetchSearchedUserHistory,
  fetchSearchedOrders,
  onStartLoadingOrders,
  setOrdersSearch,
  setExternalOrderToEdit,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  clearOrdersSearch,
  fetchExistingUserDataForUpdate,
  clearUserExistError,
} from "../../../actions"
import axios from "axios"
import Cookies from "js-cookie"
import UserTabNavigator from "./UserTabNavigator"
import NotificationModal from "../../Modals/NotificationModal"
import { fetchIfEmpty } from "../../../utils"
import "./style.scss"

class UserCreateEditScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showCreateUpdateModal: false,
      showErrorModal: false,
      validationErr: null,
      showRemoveTokenSuccess: false,
      showRemoveTokenFail: false,
      showUpdateBtns: true,
    }
  }

  componentDidMount() {
    const { unauthorized, history } = this.props
    const token = Cookies.get("token")
    if (!token) {
      history.push("/")
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }
    fetchIfEmpty(this.props.allStationsArr, this.props.fetchAllStationData)
    fetchIfEmpty(
      this.props.userOrdersData.orderTypes,
      this.props.fetchOrderTypes
    )
    fetchIfEmpty(
      this.props.userOrdersData.ordersStatusesArr,
      this.props.fetchAllOrderStatuses
    )

    this.props.getAllCountries()
    this.props.getLanguages()
    this.props.fetchUserRoles()
    this.props.clearUserHistory()
    this.props.clearOrdersSearch()

    const userId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.userId
        ? this.props.match.params.userId
        : "new"

    this.setUser(userId)
  }

  setUser(userId) {
    if (userId === "new") {
      this.props.clearCurrentCreateEditUserData()
      this.props.clearOrdersSearch()
      this.props.clearUserHistory()
      return
    }

    this.props.fetchExistingUserDataForUpdate(userId)
    this.props.onLoadingUserHistory()
    let { userHistorySearch } = this.props
    userHistorySearch.userId = userId
    this.props.fetchSearchedUserHistory(userHistorySearch)

    const { userData } = this.props
    let { userOrdersSearch } = this.props
    if (userData && userData.mobilePhone) {
      userOrdersSearch.searchText = userData.mobilePhone
      userOrdersSearch.fromDate = this.getDefaultFromDate()

      this.props.onStartLoadingOrders()

      this.props.fetchSearchedOrders(userOrdersSearch)
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentCreateEditUserData()
    this.props.clearOrdersSearch()
    this.props.clearUserHistory()
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
    if (newProps.match.params.userId !== this.props.match.params.userId) {
      const userId =
        newProps.match && newProps.match.params && newProps.match.params.userId
          ? newProps.match.params.userId
          : "new"
      this.setUser(userId)
    }
    const {
      CreateUpdateRequsetSuccess,
      CreateUpdateRequestError,
      ccTokenRemovedSuccess,
      ccTokenRemovedFail,
    } = newProps
    const {
      CreateUpdateRequsetSuccess: oldSuccess,
      CreateUpdateRequestError: oldErr,
    } = this.props

    if (
      CreateUpdateRequsetSuccess &&
      CreateUpdateRequsetSuccess !== oldSuccess
    ) {
      this.setState({ showErrorModal: false })
      this.setState({ showCreateUpdateModal: true })
    }

    if (CreateUpdateRequestError && CreateUpdateRequestError != oldErr) {
      this.setState({ showCreateUpdateModal: false })
      this.setState({ showErrorModal: true })
    }
    if (ccTokenRemovedSuccess) {
      this.setState({ showRemoveTokenSuccess: true })
    }
    if (ccTokenRemovedFail) {
      this.setState({ showRemoveTokenFail: true })
    }

    const { userData } = this.props
    const { userData: userDataNew } = newProps
    const haveNewUserMobile =
      userDataNew &&
      userDataNew.mobilePhone &&
      userDataNew.mobilePhone.length > 0

    if (haveNewUserMobile && userData.mobilePhone !== userDataNew.mobilePhone) {
      let { userOrdersSearch } = newProps
      userOrdersSearch.searchText = userDataNew.mobilePhone
      userOrdersSearch.fromDate = this.getDefaultFromDate()

      this.props.onStartLoadingOrders()
      this.props.fetchSearchedOrders(userOrdersSearch)
    }
  }

  onSubmitUserDataClick() {
    const { userData, isThatNewUser, logedUserData } = this.props

    let validationErr = this.isUserValidationOk(userData)

    if (validationErr) {
      this.setState({ validationErr })
      return
    }

    if (isThatNewUser) {
      userData.source = "BackOffice"
      this.props.createNewUser(userData)
    } else {
      this.props.updateUserData(userData)
    }

    this.setState({ validationErr })
  }

  isUserValidationOk(userData) {
    const { mobilePhone, email } = userData

    const isMobileInvalid = isMobileNumInvalid(mobilePhone)
    const isEmailInputInvalid = isEmailInvalid(email)

    if (isMobileInvalid) {
      return isMobileInvalid
    }
    if (isEmailInputInvalid) {
      return isEmailInputInvalid
    }
    if (userData.stationId == "") {
      return "אנא בחר עמדה"
    }
  }

  showUpdateBtn(show) {
    this.setState({ showUpdateBtns: show ? true : false })
  }

  onUserHistoryPageClick(page) {
    let userHistorySearch = this.getSearchParam()
    userHistorySearch.page = page
    this.props.onLoadingUserHistory()

    this.props.fetchSearchedUserHistory(userHistorySearch)
  }

  onUserOrdersPageClick(page) {
    const { userData } = this.props
    if (!userData || !userData.mobilePhone) {
      return
    }
    let { userOrdersSearch } = this.props
    userOrdersSearch.page = page
    userOrdersSearch.searchText = userData.mobilePhone
    userOrdersSearch.fromDate = this.getDefaultFromDate()

    this.props.onStartLoadingOrders()
    this.props.fetchSearchedOrders(userOrdersSearch)
  }

  getDefaultFromDate() {
    let defaultDate = new Date()
    let newMonth = defaultDate.getMonth() - 6

    if (newMonth <= 0) {
      newMonth = newMonth <= 0 ? newMonth + 12 : newMonth
      defaultDate.setMonth(newMonth)
      defaultDate.setFullYear(defaultDate.getFullYear() - 1)
    } else {
      defaultDate.setMonth(newMonth)
    }
    return this.formatDateForPicker(defaultDate)
  }

  formatDateForPicker(date) {
    let day = date.getDate()
    if (day < 10) {
      day = "0" + day
    }

    let month = date.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    return date.getFullYear() + "-" + month + "-" + day
  }

  onOrderItemEditBtnClick(order) {
    console.log("onOrderItemEditBtnClick", order)
    this.props.setExternalOrderToEdit(order)
    this.props.history.push("/OrdersEditingScreen")
  }

  getSearchParam() {
    const userId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.userId
        ? this.props.match.params.userId
        : "new"
    let param = this.props.userHistorySearch
    param.userId = userId
    return param
  }

  render() {
    const {
      userData,
      sortedStationsNames,
      isThatNewUser,
      countries,
      languages,
      userRoles,
      errorMessage,
      userHistoryData,
      userOrdersData,
      isUserExistError,
    } = this.props
    const {
      showCreateUpdateModal,
      showErrorModal,
      validationErr,
      showRemoveTokenSuccess,
      showRemoveTokenFail,
      showUpdateBtns,
    } = this.state

    return (
      <div className="ucs-wrapper">
        <WhiteFrame>
          <div className="ucs-white-frame">
            <div className="ucs-white-frame-user-name-wrapper">
              <div className="ucs-white-frame-user-name">כרטיס לקוח</div>
            </div>
          </div>
        </WhiteFrame>

        <UserTabNavigator
          languages={languages}
          countries={countries}
          userRoles={userRoles}
          userData={userData}
          sortedStationsNames={sortedStationsNames}
          onUserDataFieldUpdate={(inputName, val) =>
            this.props.onUserDataFieldUpdate(inputName, val)
          }
          removeCCToken={() => this.props.removeCCToken(userData.id)}
          userHistoryData={userHistoryData}
          showUpdateBtn={(e) => this.showUpdateBtn(e)}
          onUserHistoryPageClick={(page) => this.onUserHistoryPageClick(page)}
          userOrdersData={userOrdersData}
          onOrderItemEditBtnClick={(itm) => this.onOrderItemEditBtnClick(itm)}
          onUserOrdersPageClick={(page) => this.onUserOrdersPageClick(page)}
        />

        <div className="ucs-btn-wrapper">
          <BlueButton
            show={showUpdateBtns}
            extraClass="ucs-update-button"
            onClick={(e) => this.onSubmitUserDataClick()}
          >
            {isThatNewUser ? "צור משתמש חדש" : "עדכן פרטי משתמש"}
          </BlueButton>

          <BlueButton
            show={showUpdateBtns}
            extraClass="ucs-update-button"
            onClick={(e) => this.props.clearCurrentCreateEditUserData()}
          >
            נקה טופס
          </BlueButton>
        </div>

        <div className="ucs-update-err-msg">{validationErr}</div>

        <NotificationModal
          show={showCreateUpdateModal}
          title={isThatNewUser ? "יצירת משתמש" : "עדכון משתמש"}
          text={isThatNewUser ? "המשתמש נוצר בהצלחה" : "המשתמש עודכן בהצלחה"}
          onOkClick={() => {
            this.setState({ showCreateUpdateModal: false })

            const param = this.getSearchParam()
            this.props.fetchSearchedUserHistory(param)
          }}
        />
        <NotificationModal
          type={"warning"}
          show={isUserExistError}
          title={"עדכון משתמש"}
          text={"משתמש עם מספר טלפון זה כבר קיים במערכת."}
          onOkClick={() => this.props.clearUserExistError()}
        />

        <NotificationModal
          show={showRemoveTokenSuccess}
          title="עדכון משתמש"
          text="טוקן הוסר בהצלחה"
          onOkClick={() => this.setState({ showRemoveTokenSuccess: false })}
        />

        <NotificationModal
          show={showRemoveTokenFail}
          title="עדכון משתמש"
          text="טוקן לא הוסר. תקלה בעת עדכון משתמש"
          onOkClick={() => this.setState({ showRemoveTokenFail: false })}
        />

        <NotificationModal
          show={showErrorModal}
          title={
            isThatNewUser ? "תקלה בעת יצירת משתמש" : "תקלה בעת עדכון המשתמש"
          }
          text={
            <div>
              לא ניתן להשלים את הפעולה
              {errorMessage ? <p>{errorMessage}</p> : ""}
            </div>
          }
          onOkClick={() => this.setState({ showErrorModal: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  createUpdateUser,
  stationsList,
  selectOptions,
  login,
  userHistory,
  ordersSearch,
  ordersStatuses,
  errors,
}) => {
  const {
    userData,
    isThatNewUser,
    CreateUpdateRequsetSuccess,
    CreateUpdateRequestError,
    languages,
    countries,
    errorMessage,
    ccTokenRemovedSuccess,
    ccTokenRemovedFail,
    isUserExistError,
  } = createUpdateUser
  const { allStationsArr, sortedStationsNames } = stationsList
  const { userRoles } = selectOptions
  const { logedUserData } = login
  const { unauthorized } = errors
  let userHistoryData = {
    items: userHistory.userHistoryList,
    isLoading: userHistory.isLoading,
    isError: userHistory.isError,
    currentSearchedPage: userHistory.currentSearchedPage,
    currentSearchedPageStep: userHistory.currentSearchedPageStep,
  }

  const userHistorySearch = userHistory.search

  let {
    searchedOrdersArr,
    isError,
    isLoading,
    currentSearchedPage,
    currentSearchedPageStep,
  } = ordersSearch
  const { ordersStatusesArr, orderStatusesObj } = ordersStatuses
  const { orderTypes } = selectOptions

  const isExistOrdersStatuses =
    ordersStatusesArr && ordersStatusesArr.length > 0
  const isExistOrdersTypes = orderTypes && orderTypes.length > 0

  searchedOrdersArr.forEach((element) => {
    element.orderFormatStatus = isExistOrdersStatuses
      ? getStatusNameFromObj(orderStatusesObj, element.orderStatus)
      : element.orderFormatStatus
    element.orderFromatType = isExistOrdersTypes
      ? getValEnumFromIndex(orderTypes, element.orderType)
      : element.orderFromatType
  })

  let userOrdersData = {
    items: searchedOrdersArr,
    isError,
    isLoading,
    currentSearchedPage,
    currentSearchedPageStep,
    ordersStatusesArr,
    orderStatusesObj,
    orderTypes,
  }

  const userOrdersSearch = ordersSearch.search

  return {
    userData,
    isThatNewUser,
    CreateUpdateRequsetSuccess,
    CreateUpdateRequestError,
    countries,
    languages,
    userRoles,
    allStationsArr,
    sortedStationsNames,
    errorMessage,
    logedUserData,
    ccTokenRemovedSuccess,
    ccTokenRemovedFail,
    userHistoryData,
    userHistorySearch,
    userOrdersData,
    userOrdersSearch,
    unauthorized,
    isUserExistError,
  }
}

export default connect(mapStateToProps, {
  createNewUser,
  updateUserData,
  onUserDataFieldUpdate,
  clearCurrentCreateEditUserData,
  getAllCountries,
  getLanguages,
  fetchAllStationData,
  fetchUserRoles,
  removeCCToken,
  clearUserHistory,
  setUserHistoryUser,
  onLoadingUserHistory,
  fetchSearchedUserHistory,
  fetchSearchedOrders,
  onStartLoadingOrders,
  setOrdersSearch,
  setExternalOrderToEdit,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  clearOrdersSearch,
  fetchExistingUserDataForUpdate,
  clearUserExistError,
})(UserCreateEditScreen)
