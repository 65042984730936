import React from "react"
import { WhiteFrame, Title } from "../../../../common"
import { DISCOUNT_TYPES_ENUM } from "../../../../../utils"
import { useHistory } from "react-router-dom"
import "./style.scss"
const CustomerDetailsFrame = ({
  order,
  user,
  coupon,
  onRemoveCoupon,
  onAddCoupon,
}) => {
  const history = useHistory()
  if (!order || !user) {
    return <div>LOADING..</div>
  }

  const {
    firstName,
    lastName,
    customerId,
    //mobilePhone,
    //email,
    orderType,
    customerCompensation,
    customerSourceCompensation,
    customerFullName,
    senderFullName,
    senderMobilePhone,
    accountType,
  } = order

  const {
    companyName,
    compensation,
    customerDiscount,
    email,
    mobilePhone,
    isMonthlyPayment,
    discountExpirationDate,
  } = user

  const renderCouponInfo = (coupon) => {
    const { couponName, discount, discountType } = coupon
    let discountText = discount
    if (discountType - 0 === 1) {
      discountText = (discount * 1.18).toFixed(2)
    }
    return (
      <>
        <Title>שם קופון:</Title>
        <Title bold extraClass="cus-det-frame-details-value info-wrapper">
          {couponName}
        </Title>

        <Title>הנחת הקופון:</Title>
        <Title bold extraClass="cus-det-frame-details-value info-wrapper">
          {discountText}
          {DISCOUNT_TYPES_ENUM[discountType]}
        </Title>
      </>
    )
  }

  const renderRemoveBtn = () => {}

  const showCouponData = (coupon, orderType = null) => {
    const couponOrderTypes = [1, 2, 4, 5]
    const isAllowedOrderType =
      couponOrderTypes.filter((t) => t === orderType).length > 0

    if (orderType == null || !isAllowedOrderType) {
      return <></>
    }
    const isAllowedOrderStatus =
      order.orderStatus != 5 && order.orderStatus != 6
    const isEmptyCoupon =
      !coupon || !coupon.couponId || coupon.couponId === null

    if (isEmptyCoupon && (!isAllowedOrderStatus || order.isPayed)) {
      return <></>
    }

    if (isEmptyCoupon) {
      return (
        <div
          className="info-wrapper info-wrapper-btn fa-plus-square-o "
          aria-hidden="true"
          onClick={() => onAddCoupon(orderType)}
          alt="הוספת קופון להזמנה"
        >
          +
        </div>
      )
    }

    if (isAllowedOrderStatus && !order.isPayed) {
      return (
        <>
          <div className="cus-det-frame-details">
            {renderCouponInfo(coupon)}
            <Title
              extraClass="fa fa-trash-o info-wrapper-btn"
              aria-hidden="true"
              onClick={() => onRemoveCoupon()}
              alt="הורדת קופון מהזמנה"
            ></Title>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="cus-det-frame-details">{renderCouponInfo(coupon)}</div>
      </>
    )
  }

  const renderCompensationChanges = (value, newValue) => {
    if (value === newValue) {
      return <></>
    }
    return (
      <div className="cus-det-frame-new-debt-wrapper">
        <div>ישתנה לאחר שמירה ל-</div>
        <div className="cus-det-frame-debt-amount">{newValue} ₪</div>
      </div>
    )
  }

  let newCustomerCompensation = customerCompensation
  let customerFullNameToShow = customerFullName
  let mobilePhoneToShow = mobilePhone
  if (orderType === 5) {
    newCustomerCompensation = customerSourceCompensation
    customerFullNameToShow = senderFullName
    mobilePhoneToShow = senderMobilePhone
  }

  return (
    <WhiteFrame>
      <div className="cus-det-frame">
        <div className="cus-det-frame-faded-title">לקוח משלם</div>
        <div>
          <div className="cus-det-frame-user-name-wrapper">
            <div
              className="cus-det-frame-user-name"
              onClick={() =>
                history.push("/UserCreateEditScreen/" + customerId)
              }
            >
              {customerFullNameToShow}
            </div>
            <Title>{/* (...{customerId.substring(0, 6)}) */}</Title>
          </div>
          <Title bold>{companyName || "לקוח ללא חברה"}</Title>
        </div>
        <div className="cus-det-frame-debt-wrapper">
          <div>יתרת פיצוי ללקוח</div>
          <div className="cus-det-frame-debt-amount">{compensation} ₪</div>
        </div>
        {renderCompensationChanges(compensation, newCustomerCompensation)}
      </div>
      <div className="cus-det-frame-part2">
        <div className="cus-det-frame-faded-title">הנחות</div>
        <div className="cus-det-frame-details-wrapper">
          <div className="cus-det-frame-details">
            <Title>הנחה קבועה:</Title>
            <Title bold extraClass="cus-det-frame-details-value info-wrapper">
              {customerDiscount}%
            </Title>
            {accountType == 1 ? "" : "( לא בשימוש בהזמנה זו )"}
          </div>
          {discountExpirationDate && (
            <div className="cus-det-frame-details">
              <Title>תוקף הנחה: </Title>
              <Title extraClass="cus-det-frame-details-value">
                {" "}
                {discountExpirationDate.substring(0, 10)}{" "}
              </Title>
            </div>
          )}
          {showCouponData(coupon, orderType)}
        </div>
      </div>
      <div className="cus-det-frame-part2">
        <div className="cus-det-frame-faded-title">פרטי יצירת קשר:</div>
        <div className="cus-det-frame-details-wrapper">
          <div className="cus-det-frame-details">
            <Title>טלפון:</Title>
            <Title bold extraClass="cus-det-frame-details-value">
              {mobilePhone}
            </Title>
          </div>
          <div className="cus-det-frame-details">
            <Title>אימייל:</Title>
            <Title bold extraClass="cus-det-frame-details-value">
              {email}
            </Title>
          </div>
          {isMonthlyPayment && (
            <div className="cus-det-frame-details">
              <Title bold extraClass="user-is-monthly-payment">
                תשלום חודשי
              </Title>
            </div>
          )}
        </div>
      </div>
    </WhiteFrame>
  )
}

export default CustomerDetailsFrame
