import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"
import {
  REQUEST_STATIONS_WITH_SNAPSHOTS,
  FETCH_STATIONS_WITH_SNAPSHOTS_SUCCESS,
  FETCH_STATIONS_WITH_SNAPSHOTS_FAIL,
  CLEAR_STATIONS_WITH_SNAPSHOTS,
  REQUEST_SEARCH_STATION_SNAPSHOTS,
  SEARCH_STATION_SNAPSHOTS_SUCCESS,
  SEARCH_STATION_SNAPSHOTS_FAIL,
  CLEAR_STATION_SNAPSHOTS_SEARCH,
} from "./types"

export const fetchStationWithSnapshots = () => {
  return (dispatch) => {
    dispatch({ type: REQUEST_STATIONS_WITH_SNAPSHOTS })
    axios
      .get(api.getStationsWithSnapshots)
      .then((res) => {
        dispatch({
          type: FETCH_STATIONS_WITH_SNAPSHOTS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: FETCH_STATIONS_WITH_SNAPSHOTS_FAIL,
          payload: err,
        })
      })
  }
}

export const clearStationsWithSnapshots = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STATIONS_WITH_SNAPSHOTS })
  }
}

export const searchStationSnapshots = (stationId, fromDate, toDate) => {
  const formattedFromDate = fromDate.toISOString()
  const formattedToDate = toDate.toISOString()
  return (dispatch) => {
    dispatch({ type: REQUEST_SEARCH_STATION_SNAPSHOTS })
    axios
      .get(
        api.searchStationSnapshots(
          stationId,
          formattedFromDate,
          formattedToDate
        )
      )
      .then((res) => {
        dispatch({
          type: SEARCH_STATION_SNAPSHOTS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: SEARCH_STATION_SNAPSHOTS_FAIL,
          payload: err,
        })
      })
  }
}

export const clearSearchSnapshots = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STATION_SNAPSHOTS_SEARCH })
  }
}
