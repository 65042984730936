import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Manager from "./Manager/Manager.js"
import WHOperator from "./WHOperator/WHOperator.js"
import Driver from "./Driver/Driver.js"
import "./style.scss"
import Cookies from "js-cookie"
import NotAliveStations from "./NotAliveStations"
import StationsVoltage from "./StationsVoltage"
import {
  getDownStations,
  getStationsVoltage,
  getStationsVoltagePerStation,
} from "../../../actions"
import Tile from "../../common/Tile/Tile.js"
import "./style.scss"

const HomeScreen = (props) => {
  const [isMobile, setIsMobile] = useState(false)
  const [defaultValueVoltageSelect, setDefaultValueVoltageSelect] = useState({
    value: 0,
    label: "כול העמדות",
  })
  const [loadDownStationsInterval, setLoadDownStationsInterval] = useState(null)
  const [loadStationsVoltageInterval, setLoadStationsVoltageInterval] =
    useState(null)

  const { logedUserData, unauthorized } = useSelector((state) => ({
    logedUserData: state.login.logedUserData,
    unauthorized: state.errors.unauthorized,
  }))
  const dispatch = useDispatch()

  useEffect(() => {
    if (unauthorized) {
      props.history.push("/")
    }

    dispatch(getDownStations())
    dispatch(getStationsVoltage())

    const downStationsInterval = setInterval(() => {
      dispatch(getDownStations())
    }, 60000)
    setLoadDownStationsInterval(downStationsInterval)

    const voltageInterval = setInterval(() => {
      loadStationsVoltage()
    }, 60000)
    setLoadStationsVoltageInterval(voltageInterval)
    isMobileDevice() ? setIsMobile(true) : setIsMobile(false)
    return () => {
      clearInterval(downStationsInterval)
      clearInterval(voltageInterval)
    }
  }, [dispatch])

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  const loadStationsVoltage = () => {
    if (defaultValueVoltageSelect.value === 0) {
      dispatch(getStationsVoltage())
    } else {
      dispatch(
        getStationsVoltagePerStation(defaultValueVoltageSelect.stationId)
      )
    }
  }

  const onSelectStationOnVoltage = (item) => {
    setDefaultValueVoltageSelect(item)

    if (item.value === 0) {
      dispatch(getStationsVoltage())
      console.log(`getStationsVoltage`)
    } else {
      dispatch(getStationsVoltagePerStation(item.stationId))
      console.log(`getStationsVoltagePerStation ${item.stationNumber}`)
    }
  }

  const switchRole = (role) => {
    switch (role) {
      case "Manager":
        return (
          <div>
            <Manager history={props.history} />
            <div className={"wh-operator-wrapper"}>
              <WHOperator history={props.history} />
            </div>
            <div className="delivery-btns-wrapper">
              <h3> פעולות:</h3>
              <div className="delivery-btns-holder">
                <Tile title="סריקה תעודות" link="/SacksScreen" />
                <Tile title="סריקה תעודות מובייל" link="/ScanSackMobilePage" />
                <Tile title="סריקת הזמנות מובייל" link="/ScanOrderMobilePage" />
                <Tile title="סריקה CheapSim" link="/CheapSimPage" />
                <Tile title="סריקה כללי" link="/GeneralReceptionPage" />
                <Tile title="תעודת משלוח" link="/ShippingCertificatePage" />
                <Tile title="סגירת שק" link="/CloseOrdersSackPage" />
                <Tile title="חיפוש שקי מחסן" link="/SearchStorageSackPage" />
                <Tile title="חבילות פגומות" link="/DamagedPackagesPage" />
              </div>
              <div />
            </div>
            <div className="separator"></div>

            <NotAliveStations history={props.history} isMobile={isMobile} />
            <StationsVoltage
              history={props.history}
              defaultValueVoltageSelect={defaultValueVoltageSelect}
              onSelectStationOnVoltage={onSelectStationOnVoltage}
              isMobile={isMobile}
            />
            <Driver history={props.history} />
          </div>
        )

      case "WHOperator":
        return <WHOperator history={props.history} />
      default:
        break
    }
  }

  const firstName = logedUserData?.firstName || Cookies.get("firstName")
  const lastName = logedUserData?.lastName || Cookies.get("lastName")
  const role = logedUserData?.role || Cookies.get("role")

  return (
    <section className="homePage">
      <h1 className="welcome-title">
        היי&nbsp;
        {firstName} {lastName}!
      </h1>
      {switchRole(role)}
    </section>
  )
}

export default HomeScreen
