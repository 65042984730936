import React, { useEffect, useRef } from "react"
import { SmallHeader, BlueButton } from "../../common"
import "./style.scss"

const NotificationModal = ({ show, type, title, text, onOkClick }) => {
  const modalContainerRef = useRef(null)

  useEffect(() => {
    if (show) {
      modalContainerRef.current?.focus()
    }
  }, [show])

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onOkClick()
    }
  }

  if (!show) {
    return null
  }

  return (
    <div className="noti-modal-overlay">
      <div
        className="noti-modal-container"
        ref={modalContainerRef}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        onClick={(e) => e.stopPropagation()}
      >
        <SmallHeader title={title} type={type} />
        <div className="noti-modal-content-wrapper">{text}</div>
        <div className="noti-btn-wrapper">
          <BlueButton
            autoFocus
            tabIndex={0}
            extraClass="noti-modal-button"
            onClick={onOkClick}
          >
            אישור
          </BlueButton>
        </div>
      </div>
    </div>
  )
}

export default NotificationModal
