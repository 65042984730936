import React, { useEffect, useState } from "react"
import "./Toast.scss"

const Toast = ({ isShow, message, position }) => {
  const [show, setShow] = useState(isShow)

  useEffect(() => {
    if (isShow) {
      setShow(true)
      setTimeout(() => setShow(false), 3000)
    }
  }, [isShow])

  if (!show) return null
  const positionClass = position || "bottom-right"
  return <div className={`toast ${positionClass}`}>{message}</div>
}

export default Toast
