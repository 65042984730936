import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import LockersList from "../LockersList"
import StationPhotos from "../StationPhotos/StationPhotos.js"
import Select from "react-select"
import NotificationModal from "../../../Modals/NotificationModal"
import {
  CheckboxAndLabel,
  Spinner,
  LabeledTextInput,
  LabeledSelect,
  LabeledTextarea,
  BlueButton,
} from "../../../common"
import {
  getStationById,
  updateStation,
  editStation,
  editLockers,
  cleanStationForm,
  closeNotificationSuccessed,
  closeNotificationFailture,
  showStationLoading,
  fetchLockerTypeSelectOptions,
  fetchRoutes,
  fetchCities,
  getPostalCode,
  closeNotificationPostalCodeError,
  closeNotificationPostalCodeInvalid,
  fetchLockerSizes,
  getAllRouteAreaCodes,
  isAreaCodeCanBeLinkedToStation,
  clearAreaCodeCanBeLinked,
} from "../../../../actions"
import { fetchIfEmpty } from "../../../../utils"
import "./style.scss"

const EditStation = ({ history }) => {
  const dispatch = useDispatch()

  const {
    station,
    stationLockers,
    showNotification_Success,
    showNotification_Faiture,
    isLoading,
    showPostalCodeError,
    showPostalCodeInvalid,
  } = useSelector((state) => state.stationsSection)
  const { isAreaCodeCanBeLinked, stationsItemSelectList } = useSelector(
    (state) => state.stationsList
  )
  const unauthorized = useSelector((state) => state.errors.unauthorized)
  const routesList = useSelector((state) => state.routes.routes)
  const { citiesList } = useSelector((state) => state.cities)
  const routeAreaCodes = useSelector(
    (state) => state.routeAreaCode.routeAreaCodes
  )

  const { lockerTypes, lockersSizes, priceLists } = useSelector(
    (state) => state.selectOptions
  )

  const [routes, setRoutes] = useState(routesList)
  const [showReturnPackageError, setShowReturnPackageError] = useState(false)
  const [selectedRouteId, setSelectedRouteId] = useState(null)
  const [formatedRouteAreacCodes, setFormatedRouteAreacCodes] = useState([])
  const [showAreaCodeCanBeLinkedMsg, setShowAreaCodeCanBeLinkedMsg] =
    useState(false)

  const paymentVersionList = [
    { value: 1, label: "דור ראשון" },
    { value: 2, label: "דור שני" },
    { value: 3, label: "Nayax" },
    { value: 4, label: "אפליקציית מובייל" },
    { value: 5, label: "Hybrid" },
    { value: 6, label: "Pudo" },
  ]

  useEffect(() => {
    dispatch(cleanStationForm())
    fetchIfEmpty(lockerTypes, () => dispatch(fetchLockerTypeSelectOptions()))
    fetchIfEmpty(routesList, () => dispatch(fetchRoutes()))
    fetchIfEmpty(citiesList, () => dispatch(fetchCities()))
    fetchIfEmpty(lockersSizes, () => dispatch(fetchLockerSizes()))
    dispatch(getAllRouteAreaCodes())
    dispatch(showStationLoading())
    dispatch(getStationById(station?.stationId))

    const emptyRow = { value: null, label: "ללא מסלול" }
    setRoutes((prevRoutes) => [...prevRoutes, emptyRow])
  }, [])

  useEffect(() => {
    if (unauthorized) {
      history.push("/")
    }
  }, [unauthorized])

  useEffect(() => {
    if (routeAreaCodes.length > 0 && formatedRouteAreacCodes.length === 0) {
      const formatedCodes = formatRouteAreaCodesObject(routeAreaCodes)
      setFormatedRouteAreacCodes(formatedCodes)
    }
  }, [routeAreaCodes])

  useEffect(() => {
    if (station) {
      setRouteAreaCodesByRouteId(station.routeId)
      setShowAreaCodeCanBeLinkedMsg(false)
      dispatch(clearAreaCodeCanBeLinked())
    }
  }, [station])

  const setRouteAreaCodesByRouteId = (routeId) => {
    const filteredObjects = routeAreaCodes?.filter(
      (obj) => obj.routeId === routeId
    )
    const formatedCodes = formatRouteAreaCodesObject(filteredObjects)
    setFormatedRouteAreacCodes(formatedCodes)
  }

  const handleStationChange = (inputName, inputValue) => {
    let _station = { ...station, [inputName]: inputValue }

    switch (inputName) {
      case "routeAreaCodeId":
        dispatch(isAreaCodeCanBeLinkedToStation(inputValue))
        break

      case "routeId":
        setSelectedRouteId(inputValue)
        setRouteAreaCodesByRouteId(inputValue)
        break

      case "isLaundryActive":
        const isLaundryActive = inputValue === "true" || inputValue === true
        _station.minimumLaundryEmptyLockers = isLaundryActive
          ? _station.minimumLaundryEmptyLockers || 2
          : 0
        break

      case "isDecathlon":
      case "isAmshat":
      case "isYadMordechai":
      case "isBerorHayil":
      case "isSdeMoshe":
      case "isAmirim":
      case "isApple":
      case "isTAU":
      case "isMaayanZvi":
      case "isNitzanim":
        _station = resetStationFlags(inputValue ? inputName : null, _station)
        break

      default:
        break
    }

    _station = validateFields(_station)
    dispatch(editStation(_station))
  }

  const resetStationFlags = (activeFlag, station) => {
    const flags = [
      "isDecathlon",
      "isAmshat",
      "isYadMordechai",
      "isBerorHayil",
      "isSdeMoshe",
      "isAmirim",
      "isApple",
      "isTAU",
      "isMaayanZvi",
      "isNitzanim",
    ]

    flags.forEach((flag) => {
      station[flag] = flag === activeFlag
    })
    if (!activeFlag) {
      flags.forEach((flag) => {
        station[flag] = false
      })
    }
    return station
  }

  const validateFields = (_station) => {
    if (_station.minimumLaundryEmptyLockers === "") {
      _station.minimumLaundryEmptyLockers = 0
    }
    return _station
  }

  const isReturnPackagesValid = (station) => {
    const {
      isReturnPackageActive,
      postalCode,
      cityId,
      branchName_en,
      branchAddress_en,
      routeAreaCodeId,
    } = station

    if (isReturnPackageActive) {
      if (
        !postalCode ||
        !cityId ||
        !branchName_en ||
        !branchAddress_en ||
        !routeAreaCodeId ||
        routeAreaCodeId === undefined
      ) {
        setShowReturnPackageError(true)
        return false
      }
    }
    return true
  }

  const handleSubmitButtonClick = (station) => {
    if (!isAreaCodeCanBeLinked) {
      setShowAreaCodeCanBeLinkedMsg(true)
      return
    }
    let isReturnValid = isReturnPackagesValid(station)
    if (!isReturnValid) {
      return
    }
    dispatch(showStationLoading())
    station = validateFields(station)
    dispatch(updateStation(station, stationLockers))
  }

  const formatRouteAreaCodesObject = (arrayOfObjects) => {
    return arrayOfObjects.map((obj) => ({
      label:
        obj.routeAreaCodeName +
        (obj.routeAreaCodeDesc ? " - " + obj.routeAreaCodeDesc : ""),
      value: obj.id,
      routeId: obj.routeId,
    }))
  }

  // const findValueForRouteAreaCodeId = () => {
  //   let value = formatedRouteAreacCodes.find(
  //     (option) => option.value == station?.routeAreaCodeId
  //   )
  //   setSelectedAreaCode(value)
  // }

  const handleLockersChange = (editedLocker, lockerIndex) => {
    const _lockers = stationLockers.map((locker, index) =>
      index === lockerIndex ? editedLocker : locker
    )
    dispatch(editLockers(_lockers))
  }

  const openVideoUrl = (videoUrl, event) => {
    event.preventDefault()
    window.open(videoUrl, "_blank")
  }

  const handleOpenCoordinatesClick = (latitude, longitude, event) => {
    event.preventDefault()
    window.open(
      `https://www.google.com/maps/place/${latitude},${longitude}`,
      "_blank"
    )
  }

  const getSelectedItem = (id, list, key = "value") => {
    if (id && id !== "") {
      return list?.filter((item) => item[key] === id)[0]
    }
    return { value: null, label: "" }
  }

  // const route =
  //   station?.routeId && station?.routeId !== ""
  //     ? routes.find((r) => r.value === station?.routeId)
  //     : { value: null, label: "ללא מסלול" }

  const nearByStation = getSelectedItem(
    station?.nearByStationId,
    stationsItemSelectList,
    "stationId"
  )
  const nearByStation2 = getSelectedItem(
    station?.nearByStationId2,
    stationsItemSelectList,
    "stationId"
  )
  const nearByStation3 = getSelectedItem(
    station?.nearByStationId3,
    stationsItemSelectList,
    "stationId"
  )

  return (
    <div className="station-edit">
      <div className="header">
        <h1>עדכון עמדה</h1>
        <BlueButton
          extraClass={"btn-branch-card"}
          onClick={() =>
            history.push(
              `/StationsScreen/BranchCard?branchId=${station?.branchId}`
            )
          }
        >
          לכרטיס סניף
        </BlueButton>
        <BlueButton
          extraClass={"btn-branch-card"}
          onClick={() =>
            history.push(
              `/StationsScreen/StationPhotos/?stationId=${station?.stationId}&stationNumber=${station?.stationNumber}`
            )
          }
        >
          לתמונות עמדה
        </BlueButton>
      </div>

      <div className="ss-container">
        <div className="stations-section-station-info">
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText="מס' עמדה"
              value={station?.stationNumber}
              inputSize={6}
              onChange={(e) =>
                handleStationChange("stationNumber", e.target.value)
              }
            />
            <LabeledTextInput
              labelText="שם סניף"
              value={station?.branchName}
              inputSize={30}
              onChange={(e) =>
                handleStationChange("branchName", e.target.value)
              }
            />
            <LabeledTextInput
              labelText="כתובת סניף"
              value={station?.branchAddress}
              inputSize={50}
              onChange={(e) =>
                handleStationChange("branchAddress", e.target.value)
              }
            />
          </div>
          <div className="address-eng">
            <LabeledTextInput
              labelText="שם סניף באנגלית (עם עיר בסוגריים)"
              value={station?.branchName_en}
              inputSize={30}
              onChange={(e) =>
                handleStationChange("branchName_en", e.target.value)
              }
            />
            <LabeledTextInput
              labelText="כתובת סניף באנגלית (כתובת ללא עיר)"
              value={station?.branchAddress_en}
              inputSize={50}
              maxlength={45}
              onChange={(e) =>
                handleStationChange("branchAddress_en", e.target.value)
              }
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText="שם עמדה לסמס  (שם העמדה שישלח בסמס. אם אין ערך בשדה ישלח שם הסניף) (עם עיר בסוגריים)"
              value={station.branchDisplayName}
              inputSize={100}
              onChange={(e) => {
                handleStationChange("branchDisplayName", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText="כתובת לסמס  (הכתובת שתשלח בסמס, אם אין ערך בשדה לא תשלח כתובת סמס) (כתובת ללא עיר)"
              value={station.branchDisplayAddress}
              inputSize={100}
              onChange={(e) => {
                handleStationChange("branchDisplayAddress", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <div className="container-select route-select">
              <div className="route-label ss-label">{"מסלול הפצה"}</div>
              <Select
                classNamePrefix="base-select"
                placeholder={"מסלול הפצה"}
                value={routes.find(
                  (option) => option.value == station?.routeId
                )}
                options={routes}
                onChange={(e) => handleStationChange("routeId", e.value)}
              />
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <div className="container-select area-code-select">
              <div className="route-label ss-label">{"קוד אזור מסלול"}</div>
              <Select
                key={selectedRouteId}
                classNamePrefix="base-select"
                value={formatedRouteAreacCodes?.find(
                  (option) => option.value == station?.routeAreaCodeId
                )}
                options={formatedRouteAreacCodes}
                onChange={(e) =>
                  handleStationChange("routeAreaCodeId", e.value)
                }
              />
              {!isAreaCodeCanBeLinked && (
                <div className="area-code-limit-msg">
                  חריגה מהמגבלה המותרת לעמדות הקשורות לקוד איזור זה.
                </div>
              )}
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"מס' שק במחסן"}
              value={
                station?.storageSackId ? station?.storageSackId : "לא קיים"
              }
              disabled={true}
              inputSize={12}
            />
          </div>
          <div className="stations-section-station-info-row">
            <div className="container-select city-select">
              <div className="route-label ss-label">עיר</div>
              <Select
                classNamePrefix="base-select"
                placeholder={"עיר"}
                value={citiesList.find((c) => c.value === station?.cityId)}
                options={citiesList}
                onChange={(e) => handleStationChange("cityId", e.value)}
              />
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"מספר עמדה בבר הפצה"}
              value={station?.barStationNumber}
              inputSize={12}
              onChange={(e) => {
                handleStationChange("barStationNumber", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"קוד תחנה בDHL"}
              value={station?.dhlStationNumber}
              inputSize={12}
              onChange={(e) => {
                handleStationChange("dhlStationNumber", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"מספר קו בDHL"}
              value={station?.dhlRouteCode}
              inputSize={12}
              onChange={(e) => {
                handleStationChange("dhlRouteCode", e.target.value)
              }}
            />
          </div>

          <div className="checkbox-holder">
            <div className="chebox-container">
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isDeliveryActive}
                  onChange={(e) => {
                    handleStationChange("isDeliveryActive", e.target.checked)
                  }}
                >
                  {"האם העמדה זמינה לקבלת חבילות?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isLaundryActive}
                  onChange={(e) => {
                    handleStationChange("isLaundryActive", e.target.checked)
                  }}
                >
                  {"האם העמדה זמינה לשרותי כביסה?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isLockerRentActive}
                  onChange={(e) => {
                    handleStationChange("isLockerRentActive", e.target.checked)
                  }}
                >
                  {"האם העמדה זמינה לשרותי השכרת תא?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isLockerToLockerDeliveryActive}
                  onChange={(e) => {
                    handleStationChange(
                      "isLockerToLockerDeliveryActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה לשרותי משלוח בין תאים?"}
                </CheckboxAndLabel>
              </div>
              <CheckboxAndLabel
                checked={station?.isDeliveryCompaniesActive}
                onChange={(e) => {
                  handleStationChange(
                    "isDeliveryCompaniesActive",
                    e.target.checked
                  )
                }}
              >
                {"האם העמדה פתוחה לחברות השילוח?"}
              </CheckboxAndLabel>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isAmot}
                  onChange={(e) => {
                    handleStationChange("isAmot", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בבניין אמות Amot?"}
                </CheckboxAndLabel>
              </div>
              <div
                key={station?.stationPaymentVersion}
                className="stations-section-station-info-row"
              >
                <CheckboxAndLabel
                  checked={station?.isBLE}
                  onChange={(e) => {
                    handleStationChange("isBLE", e.target.checked)
                  }}
                >
                  {"האם העמדה BLE?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isOutdoor}
                  onChange={(e) => {
                    handleStationChange("isOutdoor", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בחוץ?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isVisible}
                  onChange={(e) => {
                    handleStationChange("isVisible", e.target.checked)
                  }}
                >
                  {"האם להציג באתר?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isReturnPackageActive}
                  onChange={(e) => {
                    handleStationChange(
                      "isReturnPackageActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה להחזרות של בלדרים חיצוניים?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isReturnExelotActive}
                  onChange={(e) => {
                    handleStationChange(
                      "isReturnExelotActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה להחזרות של נהגים שלנו?"}
                </CheckboxAndLabel>
              </div>
            </div>
            <div className="chebox-container">
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isDecathlon}
                  onChange={(e) => {
                    handleStationChange("isDecathlon", e.target.checked)
                  }}
                >
                  {"האם העמדה שייכת לדקטלון?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isYadMordechai}
                  onChange={(e) => {
                    handleStationChange("isYadMordechai", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת ביד מורדכי?"}
                </CheckboxAndLabel>
              </div>

              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isBerorHayil}
                  onChange={(e) => {
                    handleStationChange("isBerorHayil", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בברור חיל?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isTAU}
                  onChange={(e) => {
                    handleStationChange("isTAU", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת באוניברסיטת תל-אביב?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isSdeMoshe}
                  onChange={(e) => {
                    handleStationChange("isSdeMoshe", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בשדה משה?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isAmirim}
                  onChange={(e) => {
                    handleStationChange("isAmirim", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת באמירים?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isApple}
                  onChange={(e) => {
                    handleStationChange("isApple", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת ב-Apple?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isAmshat}
                  onChange={(e) => {
                    handleStationChange("isAmshat", e.target.checked)
                  }}
                >
                  {`האם העמדה שייכת לאמש"ט?`}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isMaayanZvi}
                  onChange={(e) => {
                    handleStationChange("isMaayanZvi", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת  במעיין צבי?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={station?.isNitzanim}
                  onChange={(e) => {
                    handleStationChange("isNitzanim", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בניצנים?"}
                </CheckboxAndLabel>
              </div>
            </div>
          </div>
          <div className="alt-stations-section">
            <div className="container-select">
              <div className="route-label ss-label">עמדה חילופית 1</div>
              <Select
                classNamePrefix="base-select"
                placeholder="בחר עמדה חילופית 1"
                value={nearByStation}
                options={stationsItemSelectList}
                onChange={(e) =>
                  handleStationChange("nearByStationId", e.stationId)
                }
              />
            </div>
            <div className="container-select">
              <div className="route-label ss-label">עמדה חילופית 2</div>
              <Select
                classNamePrefix="base-select"
                placeholder="בחר עמדה חילופית 2"
                value={nearByStation2}
                options={stationsItemSelectList}
                onChange={(e) =>
                  handleStationChange("nearByStationId2", e.stationId)
                }
              />
            </div>
            <div className="container-select">
              <div className="route-label ss-label">עמדה חילופית 3</div>
              <Select
                classNamePrefix="base-select"
                placeholder="בחר עמדה חילופית 3"
                value={nearByStation3}
                options={stationsItemSelectList}
                onChange={(e) =>
                  handleStationChange("nearByStationId3", e.stationId)
                }
              />
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"כמות מינימלית של תאים פנויים עבור שירותי כביסה"}
              value={station?.minimumLaundryEmptyLockers}
              type={"number"}
              maxsize="12"
              inputSize={12}
              onChange={(e) => {
                handleStationChange(
                  "minimumLaundryEmptyLockers",
                  e.target.value
                )
              }}
            />
          </div>
          <div className="stations-section-station-info">
            <LabeledTextInput
              labelText={"longitude"}
              value={station?.longitude}
              type="number"
              maxsize="12"
              pattern="^[-,0-9]+${1-12}"
              onChange={(e) => {
                handleStationChange("longitude", e.target.value)
              }}
            />
            <span className="comma-separator">,</span>
            <LabeledTextInput
              labelText={"latitude"}
              value={station?.latitude}
              type="number"
              maxsize="12"
              pattern="^[-,0-9]+${1-12}"
              onChange={(e) => {
                handleStationChange("latitude", e.target.value)
              }}
            />
            <button
              className="ss-button"
              onClick={(e) =>
                handleOpenCoordinatesClick(
                  station?.latitude,
                  station?.longitude,
                  e
                )
              }
            >
              {"פתח במפה"}
            </button>
          </div>
          <div className="textarea-container">
            <LabeledTextInput
              labelText="מיקוד"
              value={station?.postalCode}
              type={"text"}
              pattern="\d*"
              maxLength="16"
              onChange={(e) => {
                handleStationChange("postalCode", e.target.value)
              }}
            />
            <button
              className="ss-button"
              onClick={(e) =>
                dispatch(getPostalCode(station?.latitude, station?.longitude))
              }
            >
              חשב מיקוד
            </button>
          </div>
          <div className="video-url">
            <LabeledTextarea
              labelText={"סרטון כיצד למצוא את התחנה"}
              value={station?.videoUrl}
              type={"text"}
              pattern="\d*"
              onChange={(e) => {
                handleStationChange("videoUrl", e.target.value)
              }}
            />
            <button
              className="ss-button"
              onClick={(e) => openVideoUrl(station?.videoUrl, e)}
            >
              פתח קישור
            </button>
          </div>
          <div className="textarea-container">
            <LabeledTextarea
              labelText={"שעות זמינות"}
              value={station?.availabilityHours}
              inputSize={300}
              rows="3"
              onChange={(e) => {
                handleStationChange("availabilityHours", e.target.value)
              }}
            />
          </div>
          <div className="container-select">
            <div
              key={station?.stationPaymentVersion}
              className="payment-version-wrapper"
            >
              <div className="payment-version-label">{"גרסת תשלום"}</div>
              <Select
                defaultValue={
                  station?.stationPaymentVersion
                    ? paymentVersionList.find(
                        (o) => o.value === station?.stationPaymentVersion
                      )
                    : { value: 2, label: "version 2" }
                }
                options={paymentVersionList}
                onChange={(e) => {
                  handleStationChange("stationPaymentVersion", e.value)
                }}
              />
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText={"מספר סידורי של Nayax Terminal"}
                value={station?.nayaxTerminalSN}
                type={"text"}
                pattern="\d*"
                maxLength="16"
                onChange={(e) => {
                  handleStationChange("nayaxTerminalSN", e.target.value)
                }}
              />
            </div>
          </div>
          <div className="container-select">
            <LabeledSelect
              emptyOption={false}
              labelText={"עדיפות עמדה"}
              options={Array.from({ length: 21 }, (_, i) => i)}
              value={station?.stationPriority}
              onChange={(e) => {
                handleStationChange("stationPriority", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"קוד לפתיחת לוקרים באפליקצית נהגים (BLE)"}
              value={station?.stationCodeBLE}
              readOnly
              disabled
            />
          </div>
        </div>
        <div>
          <LabeledSelect
            labelText="מחירון"
            options={priceLists}
            value={station?.priceListId}
            onChange={(e) => {
              handleStationChange("priceListId", e.target.value)
            }}
          />
        </div>
        <LockersList
          lockers={stationLockers}
          onLockersChange={handleLockersChange}
        />
      </div>
      <button
        className="ss-button"
        onClick={(e) => {
          handleSubmitButtonClick(station)
        }}
      >
        שמור
      </button>

      <NotificationModal
        show={showNotification_Success}
        title={" עדכון עמדה מס' " + station?.stationNumber}
        text={"העמדה עודכנה בהצלחה"}
        onOkClick={() => {
          dispatch(closeNotificationSuccessed())
          history.goBack()
        }}
      />
      <NotificationModal
        show={showNotification_Faiture}
        title={" עדכון עמדה מס' " + station?.stationNumber}
        text={"עדכון עמדה נכשל"}
        onOkClick={() => dispatch(closeNotificationFailture())}
      />
      <NotificationModal
        show={showPostalCodeError}
        title={"יצירת מיקוד"}
        text={"כישלון ביצירת מיקוד"}
        onOkClick={() => dispatch(closeNotificationPostalCodeError())}
      />

      <NotificationModal
        show={showPostalCodeInvalid}
        title={"יצירת מיקוד"}
        text={"מיקוד שקיבלת לא חוקי, אנא חפש ידני."}
        onOkClick={() => dispatch(closeNotificationPostalCodeInvalid())}
      />

      <NotificationModal
        show={showReturnPackageError}
        type={"warning"}
        title={"עדכון עמדה נכשל"}
        text={
          "חובה להכניס מיקוד, עיר, קוד איזור ,שם סניף באנגלית וכתובת סניף באנגלית לעמדות שתומכות בהחזרה"
        }
        onOkClick={() => setShowReturnPackageError(false)}
      />

      <NotificationModal
        show={showAreaCodeCanBeLinkedMsg}
        type={"warning"}
        title={" מגבלה של קודי איזור עבור תחנה זו"}
        text={"אין אפשרות לקשר יותר קודי איזור לתחנה"}
        onOkClick={() => setShowAreaCodeCanBeLinkedMsg(false)}
      />
    </div>
  )
}

export default EditStation
