import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"
import {
  GET_STATION_PHOTOS_REQUEST,
  GET_STATION_PHOTOS_SUCCESS,
  GET_STATION_PHOTOS_FAIL,
  STATION_PHOTO_UPLOAD_REQUEST,
  STATION_PHOTO_UPLOAD_SUCCESS,
  STATION_PHOTO_UPLOAD_FAIL,
  STATION_PHOTO_DELETE_REQUEST,
  STATION_PHOTO_DELETE_SUCCESS,
  STATION_PHOTO_DELETE_FAIL,
  CLEAR_STATIONS_PHOTO,
} from "./types"

export const fetchStationPhotos = (stationId) => {
  return (dispatch) => {
    dispatch({
      type: GET_STATION_PHOTOS_REQUEST,
    })
    axios
      .get(api.fetchStationPhotos(stationId))
      .then((res) => {
        dispatch({
          type: GET_STATION_PHOTOS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_STATION_PHOTOS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const uploadStationPhoto = (stationId, photo) => {
  return (dispatch) => {
    dispatch({
      type: STATION_PHOTO_UPLOAD_REQUEST,
    })
    let formData = new FormData()
    formData.append("photo", photo)

    axios
      .post(api.uploadStationPhoto(stationId), formData)
      .then((res) => {
        dispatch({
          type: STATION_PHOTO_UPLOAD_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: STATION_PHOTO_UPLOAD_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const deleteStationPhoto = (stationId, fileName) => {
  return (dispatch) => {
    dispatch({
      type: STATION_PHOTO_DELETE_REQUEST,
    })
    axios
      .delete(api.deleteStationPhoto(stationId, fileName))
      .then((res) => {
        dispatch({
          type: STATION_PHOTO_DELETE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: STATION_PHOTO_DELETE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearStationPhotos = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STATIONS_PHOTO,
    })
  }
}
