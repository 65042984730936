import React from "react"
import { useSelector } from "react-redux"
import Select from "react-select"
import "./style.scss"
import { BlueButton } from "../../../../common"
import { isMobileNumInvalid, isEmailInvalid } from "../../../../../utils"

const PersonalDetailsTab = ({
  onInputChange,
  onOpenCancelSubscription,
  userData,
  countries,
  languages,
  onBlockCustomer,
  onUnblockCustomer,
}) => {
  const {
    firstName,
    lastName,
    mobilePhone,
    email,
    address,
    language,
    isDelete,
    isMailSubscribe,
    isNeedAccessibility,
  } = userData

  const { isUserInBlackList } = useSelector((state) => state.usersList)

  const countriesNames = countries.map((x) => x.countryName)
  const languagesNames = languages.map((x) => {
    return `${x.languageName} - ${x.languageNameLocal}`
  })

  const countryOptions = countriesNames.map((name) => ({
    label: name,
    value: name,
  }))
  const languageOptions = languagesNames.map((name) => ({
    label: name,
    value: name,
  }))

  return (
    <div className="adt-wrapper">
      <div className="adt-inner-wrapper name-row">
        <div className="ucs-input-wrapper">
          <label htmlFor="firstName">שם פרטי</label>
          <input
            id="firstName"
            className="ucs-input"
            placeholder="שם פרטי"
            maxLength="30"
            value={firstName}
            onChange={(e) => onInputChange("firstName", e.target.value)}
            required
          />
        </div>
        <div className="ucs-input-wrapper">
          <label htmlFor="lastName">שם משפחה</label>
          <input
            id="lastName"
            className="ucs-input"
            placeholder="שם משפחה"
            maxLength="30"
            value={lastName}
            onChange={(e) => onInputChange("lastName", e.target.value)}
            required
          />
        </div>
      </div>
      <div className="adt-inner-wrapper phone-email-row">
        <div className="ucs-input-wrapper">
          <label htmlFor="mobilePhone">נייד</label>
          <input
            id="mobilePhone"
            className="ucs-input ucs-ltr"
            placeholder="נייד"
            value={mobilePhone}
            type="number"
            maxLength="12"
            pattern="[0-9]{1-12}"
            onChange={(e) => onInputChange("mobilePhone", e.target.value)}
            required
          />
          {mobilePhone && (
            <p className="error-text">{isMobileNumInvalid(mobilePhone)}</p>
          )}
        </div>
        <div className="ucs-input-wrapper">
          <label htmlFor="email">אמייל</label>
          <input
            id="email"
            className="ucs-input ucs-ltr"
            placeholder="אמייל"
            value={email}
            type="email"
            onChange={(e) => onInputChange("email", e.target.value)}
            required
          />
          {email && <p className="error-text">{isEmailInvalid(email)}</p>}
        </div>
      </div>
      <div className="adt-inner-wrapper">
        <div className="ucs-pdt-flex1">
          <label>מדינה</label>
          <Select
            className="select-country"
            options={countryOptions}
            value={countryOptions.find(
              (opt) => opt.value === address?.countryName
            )}
            onChange={(selectedOption) =>
              onInputChange(
                "address",
                countries[countriesNames.indexOf(selectedOption.value)]
              )
            }
            placeholder="מדינה"
          />
        </div>
        <div className="ucs-pdt-flex1">
          <label>שפה</label>
          <Select
            options={languageOptions}
            value={languageOptions.find((opt) => opt.value === language)}
            onChange={(selectedOption) =>
              onInputChange(
                "language",
                languages[languagesNames.indexOf(selectedOption.value)]
                  .languageCode
              )
            }
            placeholder="שפה"
            className="select-language"
          />
        </div>
      </div>

      <div className="adt-inner-wrapper">
        <div className="ucs-pdt-flex1">
          <label htmlFor="status">סטטוס משתמש</label>
          <input
            id="status"
            className="ucs-input status"
            placeholder="סטטוס משתמש"
            value={isDelete || isDelete === undefined ? "לא פעיל" : "פעיל"}
            onChange={(e) => e}
            disabled
          />
          <div>
            {isUserInBlackList ? (
              <BlueButton
                extraClass="unblock"
                onClick={() => onUnblockCustomer()}
              >
                לבטל את החסימה
              </BlueButton>
            ) : (
              <BlueButton extraClass="block" onClick={() => onBlockCustomer()}>
                חסום משתמש
              </BlueButton>
            )}
          </div>
        </div>
        <div className="ucs-pdt-flex1">
          <div className="adt-options">
            <label>
              <input type="checkbox" checked={isMailSubscribe} disabled />
              לקוח עם מנוי דואר
            </label>
            <button
              className="ol-edit-btn mail-subscribtion"
              onClick={() => {
                onOpenCancelSubscription()
              }}
            >
              ניהול מנויים
            </button>
            <label>
              <input
                type="checkbox"
                checked={isNeedAccessibility}
                onChange={(e) => {
                  onInputChange(
                    "isNeedAccessibility",
                    e.target.checked ? true : false
                  )
                }}
              />
              <div className="fa fa-wheelchair" aria-hidden="true"></div>
              <div className="label"> נדרש נגישות</div>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalDetailsTab
