import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  fetchExistingUserDataForUpdate,
  setUserId,
  clearUserHistory,
  setUserHistoryUser,
  setAuthorizationManual,
  fetchUserByMobile,
} from "../../../actions"
import axios from "axios"
import Cookies from "js-cookie"

const UserEditExternalScreen = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { unauthorized, usersListArr, usersListArrFail } = useSelector(
    (state) => ({
      unauthorized: state.errors.unauthorized,
      usersListArr: state.usersList.usersListArr,
      usersListArrFail: state.usersList.usersListArrFail,
    })
  )

  useEffect(() => {
    const token = Cookies.get("token")
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }
    getUserByMobilePhone()
  }, [])

  useEffect(() => {
    if (usersListArrFail && unauthorized) {
      history.push("/")
    }
    if (usersListArr.length > 0) {
      const userId = usersListArr[0].userId
      onUserItemEditBtnClick(userId)
    }
  }, [usersListArr])

  const getUserByMobilePhone = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const mobilePhone = urlParams.get("mobilePhone")
    if (mobilePhone) {
      dispatch(fetchUserByMobile(mobilePhone))
    } else {
      history.push("/")
    }
  }

  const onUserItemEditBtnClick = (userId) => {
    if (userId) {
      dispatch(setAuthorizationManual())
      dispatch(fetchExistingUserDataForUpdate(userId))
      dispatch(clearUserHistory())
      dispatch(setUserHistoryUser(userId))
      history.push(`UserCreateEditScreen/${userId}`)
    } else {
      history.push("/")
    }
  }

  return <div></div>
}

export default UserEditExternalScreen
