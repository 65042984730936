import { combineReducers } from "redux"

import OrdersListReducer from "./OrdersListReducer"
import OrdersSearchReducer from "./OrdersSearchReducer"
import UsersListReducer from "./UsersListReducer"
import OrdersStatusesReducer from "./OrdersStatusesReducer"
import LockersListReducer from "./LockersListReducer"
import SelectOptionsReducer from "./SelectOptionsReducer"
import StationsSectionReducer from "./StationsSectionReducer"
import EditorSectionReducer from "./EditorSectionReducer"
import SearchFieldReducer from "./SearchFieldReducer"
import PriceListsSectionReducer from "./PriceListsSectionReducer"
import ReportsReducer from "./ReportsReducer"
import ImportReducer from "./ImportReducer"
import StationsReducer from "./StationsReducer"
// import RealTimeDashboardReducer from './RealTimeDashboardReducer';
import CreateUpdateUserReducer from "./CreateUpdateUserReducer"
import LoginReducer from "./LoginReducer"
import ErrorsReducer from "./ErrorsReducer"
import CouponsReducer from "./CouponsReducer"
import LockersHistoryReducer from "./LockersHistoryReducer"
import ServiceSuppliersReducer from "./ServiceSuppliersReducer"
import RoutesReducer from "./RoutesReducer"
import CitiesReducer from "./CitiesReducer"
import UserHistoryReducer from "./UserHistoryReducer"
import LockersInfraSensorReducer from "./LockersInfraSensorReducer"
import OrderToLockerReducer from "./OrderToLockerReducer"
import OrderSackReducer from "./OrderSackReducer"
import IsraelPostReducer from "./IsraelPostReducer"
import OrderImagesReducer from "./OrderImagesReducer"
import LockerToLockerReducer from "./LockerToLockerReducer"
import ReceptionOrdersReducer from "./ReceptionOrdersReducer"
import RouteAreaCodeReducer from "./RouteAreaCodeReducer"
import StationTasksReducer from "./StationTasksReducer"
import BranchCardReducer from "./BranchCardReducer"
import RouteUserReducer from "./RouteUserReducer"
import LockersCellsReducer from "./LockersCellsReducer"
import UserSubscriptionsReducer from "./UserSubscriptionsReducer"
import OrderStorageSackReducer from "./OrderStorageSackReducer"
import SearchOrdersHistoryReducer from "./SearchOrdersHistoryReducer"
import StationPhotosReducer from "./StationPhotosReducer"
import DamagedPackageReducer from "./DamagedPackageReducer"

export default combineReducers({
  ordersList: OrdersListReducer,
  ordersSearch: OrdersSearchReducer,
  usersList: UsersListReducer,
  lockersList: LockersListReducer,
  selectOptions: SelectOptionsReducer,
  editorSection: EditorSectionReducer,
  ordersStatuses: OrdersStatusesReducer,
  stationsSection: StationsSectionReducer,
  searchField: SearchFieldReducer,
  priceListsSection: PriceListsSectionReducer,
  reports: ReportsReducer,
  importSection: ImportReducer,
  stationsList: StationsReducer,
  //   realTimeDashboard: RealTimeDashboardReducer,
  createUpdateUser: CreateUpdateUserReducer,
  login: LoginReducer,
  errors: ErrorsReducer,
  coupons: CouponsReducer,
  lockersHistory: LockersHistoryReducer,
  suppliers: ServiceSuppliersReducer,
  routes: RoutesReducer,
  cities: CitiesReducer,
  userHistory: UserHistoryReducer,
  lockersInfraSensor: LockersInfraSensorReducer,
  orderToLocker: OrderToLockerReducer,
  orderSack: OrderSackReducer,
  israelPost: IsraelPostReducer,
  orderImages: OrderImagesReducer,
  lockerToLocker: LockerToLockerReducer,
  receptionOrders: ReceptionOrdersReducer,
  routeAreaCode: RouteAreaCodeReducer,
  stationTasks: StationTasksReducer,
  branchCard: BranchCardReducer,
  routeUser: RouteUserReducer,
  lockersCells: LockersCellsReducer,
  userSubscriptions: UserSubscriptionsReducer,
  orderStorageSack: OrderStorageSackReducer,
  searchOrdersHistory: SearchOrdersHistoryReducer,
  stationPhotos: StationPhotosReducer,
  damagedPackage: DamagedPackageReducer,
})
