import {
  FETCH_ALL_STATIONS_DATA,
  STATION_PRICE_LIST_CLEAR,
  FETCH_STATION_PRICE_LIST_EN,
  FETCH_STATION_PRICE_LIST_HE,
  FETCH_STATION_NOTIFICATIONS,
  FETCH_DOWN_STATIONS,
  FETCH_STATIONS_VOLTAGE,
  FETCH_STATION_VOLTAGE,
  IS_AREA_CODE_CAN_BE_LINKED,
  CLEAR_AREA_CODE_CAN_BE_LINKED,
  ON_SELECT_STATION_ID,
  REQUEST_STATIONS_WITH_SNAPSHOTS,
  FETCH_STATIONS_WITH_SNAPSHOTS_SUCCESS,
  FETCH_STATIONS_WITH_SNAPSHOTS_FAIL,
  CLEAR_STATIONS_WITH_SNAPSHOTS,
  REQUEST_SEARCH_STATION_SNAPSHOTS,
  SEARCH_STATION_SNAPSHOTS_SUCCESS,
  SEARCH_STATION_SNAPSHOTS_FAIL,
  CLEAR_STATION_SNAPSHOTS_SEARCH,
} from "../actions/types"

const INIT_STATE = {
  allStationsArr: [],
  formattedStationsNames: [],
  sortedStationsNames: [],
  stationPriceList: { allPricesObj: null },
  notifications: [],
  stationSelectList: [],
  stationsItemSelectList: [],
  downStations: [],
  stationsVoltage: [],
  stationsVoltageSelect: [],
  isAreaCodeCanBeLinked: true,
  selectedStationId: null,
  stationsWithSnapshots: [],
  getStationsWithSnapshotsSuccess: false,
  getStationsWithSnapshotsFail: false,
  searchStationSnapshotsSuccess: false,
  searchStationSnapshotsFail: false,
  stationSnapshots: [],
  isSnapshotsLoading: false,
}

const StationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_STATIONS_DATA:
      return handleAllStationsData(state, action.payload)

    case STATION_PRICE_LIST_CLEAR:
      return {
        ...state,
        stationPriceList: [],
      }

    case FETCH_STATION_PRICE_LIST_EN:
      return handleEnPriceList(state, action.payload)

    case FETCH_STATION_PRICE_LIST_HE:
      return handleHebPriceList(state, action.payload)

    case FETCH_STATION_NOTIFICATIONS:
      const notifications = action.payload
      return {
        ...state,
        unauthorized: false,
        notifications: notifications,
      }
    case FETCH_DOWN_STATIONS:
      return {
        ...state,
        downStations: action.payload,
      }
    case FETCH_STATIONS_VOLTAGE:
      return {
        ...state,
        stationsVoltageSelect: action.payload,
        stationsVoltage: action.payload,
      }
    case FETCH_STATION_VOLTAGE:
      return {
        ...state,
        stationsVoltage: action.payload,
      }
    case IS_AREA_CODE_CAN_BE_LINKED:
      return {
        ...state,
        isAreaCodeCanBeLinked: action.payload,
      }
    case CLEAR_AREA_CODE_CAN_BE_LINKED:
      return {
        ...state,
        isAreaCodeCanBeLinked: true,
      }
    case ON_SELECT_STATION_ID:
      return {
        ...state,
        selectedStationId: action.payload,
      }
    case REQUEST_STATIONS_WITH_SNAPSHOTS:
      return {
        ...state,
        getStationsWithSnapshotsSuccess: false,
        getStationsWithSnapshotsFail: false,
        stationsWithSnapshots: [],
      }
    case FETCH_STATIONS_WITH_SNAPSHOTS_SUCCESS:
      return {
        ...state,
        getStationsWithSnapshotsSuccess: true,
        getStationsWithSnapshotsFail: false,
        stationsWithSnapshots: action.payload,
      }
    case FETCH_STATIONS_WITH_SNAPSHOTS_FAIL:
      return {
        ...state,
        getStationsWithSnapshotsSuccess: false,
        getStationsWithSnapshotsFail: true,
        stationsWithSnapshots: [],
      }
    case CLEAR_STATIONS_WITH_SNAPSHOTS:
      return {
        ...state,
        getStationsWithSnapshotsSuccess: false,
        getStationsWithSnapshotsFail: false,
        stationsWithSnapshots: [],
      }
    case REQUEST_SEARCH_STATION_SNAPSHOTS:
      return {
        ...state,
        isSnapshotsLoading: true,
        searchStationSnapshotsSuccess: false,
        searchStationSnapshotsFail: false,
        stationSnapshots: [],
      }
    case SEARCH_STATION_SNAPSHOTS_SUCCESS:
      return {
        ...state,
        isSnapshotsLoading: false,
        searchStationSnapshotsSuccess: true,
        searchStationSnapshotsFail: false,
        stationSnapshots: action.payload,
      }
    case SEARCH_STATION_SNAPSHOTS_FAIL:
      return {
        ...state,
        isSnapshotsLoading: false,
        searchStationSnapshotsSuccess: false,
        searchStationSnapshotsFail: true,
        stationSnapshots: [],
      }
    case CLEAR_STATION_SNAPSHOTS_SEARCH:
      return {
        ...state,
        isSnapshotsLoading: false,
        searchStationSnapshotsSuccess: false,
        searchStationSnapshotsFail: false,
        stationSnapshots: [],
      }
    default:
      return state
  }
}

const handleAllStationsData = (state, allStationsArr) => {
  const stationsItemSelectList = allStationsArr.map(
    ({ stationNumber, branchName, stationId }) => ({
      value: stationNumber,
      stationId: stationId,
      label: stationNumber + " - " + branchName,
    })
  )
  const sortedStationsNames = stationsItemSelectList.sort(function (a, b) {
    return a.value - b.value
  })

  const formattedStationsNames = allStationsArr.map(
    ({ stationNumber, branchName }) => `${stationNumber} - ${branchName}`
  )

  const stationSelectList = allStationsArr.map(
    ({ stationNumber, branchName, stationId }) => ({
      value: stationId,
      label: stationNumber + " - " + branchName,
    })
  )

  return {
    ...state,
    unauthorized: false,
    allStationsArr,
    formattedStationsNames,
    stationsItemSelectList,
    sortedStationsNames,
    stationSelectList,
  }
}

const handleHebPriceList = (state, hebPriceList) => {
  return calcUnitedPriceList({
    ...state,
    stationPriceList: {
      ...state.stationPriceList,
      he: hebPriceList,
    },
  })
}

const handleEnPriceList = (state, enPriceList) => {
  return calcUnitedPriceList({
    ...state,
    stationPriceList: {
      ...state.stationPriceList,
      en: enPriceList,
    },
  })
}

const calcUnitedPriceList = (state) => {
  const { en, he } = state.stationPriceList

  if (!en || !he) return state

  const unitedPriceList = []
  const allPricesObj = {}

  for (let i = 0; i < he.length; i++) {
    const { processName: processName_he, productName: productName_he } = he[i]
    const {
      processName: processName_en,
      productName: productName_en,
      price,
    } = en[i]

    const newProductEntity = {
      ...he[i],
      ...en[i],
      processName_he,
      productName_he,
      processName_en,
      productName_en,
    }
    unitedPriceList.push(newProductEntity)

    allPricesObj[processName_en] = [
      ...(allPricesObj[processName_en] || []),
      { processName_en, processName_he, productName_he, price },
    ]

    if (allPricesObj["ironing"]) {
      allPricesObj.ironing.sort(function (x, y) {
        return x.productName_he == "חולצה"
          ? -1
          : y.productName_he == "חולצה"
          ? 1
          : 0
      })
    }
  }

  return {
    ...state,
    stationPriceList: {
      ...state.stationPriceList,
      united: unitedPriceList,
      allPricesObj,
    },
  }
}

export default StationsReducer
