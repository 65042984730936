import React, { useEffect } from "react"
import Header from "../../../../../common/List/Header"
import ReceptionOrderItem from "./ReceptionOrderItem"
import { H1, Spinner } from "../../../../../common"

import "./style.scss"

const orderHeaders = [
  "מס חבילה",
  "מס הזמנה",
  "תאריך",
  "סטטוס",
  "סוג הזמנה",
  "סוג החשבון",
  "מס עמדה",
  "שם סניף",
  "שם לקוח",
  "נייד",
  "עריכה",
]
const orderItemMap = [
  "packageNumber",
  "orderNumber",
  "orderFormatDate",
  "orderFormatStatus",
  "orderFromatType",
  "orderFromatAccount",
  "stationNumber",
  "branchName",
  "userFormatFullName",
  "mobilePhone",
  "editBtn",
]
const orderGravity = { 2: 1, 6: 1 }

const ReceptionOrdersList = ({
  extraClass,
  items,
  isError,
  isLoading,
  onEditClick,
  role,
}) => {
  useEffect(() => {
    if (role === "WHOperator") {
      const headerToremove = orderHeaders.indexOf("עריכה")
      if (headerToremove !== -1) {
        orderHeaders.splice(headerToremove, 1)
      }
      const itemToremove = orderItemMap.indexOf("editBtn")
      if (itemToremove !== -1) {
        orderItemMap.splice(itemToremove, 1)
      }
    }
  }, [])
  return (
    <div className={`ol-list-wrapper ${extraClass}`}>
      <Header titles={orderHeaders} gravity={orderGravity} />
      <div className="ol-list-items-wrapper">
        {renderItems(
          items,
          orderGravity,
          orderItemMap,
          isError,
          isLoading,
          onEditClick,
          role
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  orderGravity,
  orderItemMap,
  isError,
  isLoading,
  onEditClick,
  role
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }

  if (isLoading) {
    return (
      <div className="users-list">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <ReceptionOrderItem
        key={index}
        item={itm}
        role={role}
        itemMap={orderItemMap}
        gravity={orderGravity}
        onEditClick={(orderId) => {
          onEditClick(orderId)
        }}
        isLast={index === items.length - 1}
      />
    )
  })
}

export default ReceptionOrdersList
