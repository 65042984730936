import {
  GET_STATION_PHOTOS_REQUEST,
  GET_STATION_PHOTOS_SUCCESS,
  GET_STATION_PHOTOS_FAIL,
  STATION_PHOTO_UPLOAD_REQUEST,
  STATION_PHOTO_UPLOAD_SUCCESS,
  STATION_PHOTO_UPLOAD_FAIL,
  STATION_PHOTO_DELETE_REQUEST,
  STATION_PHOTO_DELETE_SUCCESS,
  STATION_PHOTO_DELETE_FAIL,
  CLEAR_STATIONS_PHOTO,
} from "../actions/types"

const INIT_STATE = {
  isGetPhotosLoading: false,
  getStationPhotosFail: false,
  getStationPhotosSuccess: false,
  stationPhotoUploadedSuccess: false,
  stationPhotoUploadedFail: false,
  stationPhotoDeleteSuccess: false,
  stationPhotoDeleteFail: false,
  isDeleteLoading: false,
  stationPhotosList: [],
}

const StationPhotosReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATION_PHOTOS_REQUEST:
      return {
        ...state,
        isGetPhotosLoading: true,
        getStationPhotosFail: false,
        getStationPhotosSuccess: false,
      }
    case GET_STATION_PHOTOS_SUCCESS:
      return {
        ...state,
        isGetPhotosLoading: false,
        getStationPhotosSuccess: true,
        getStationPhotosFail: false,
        stationPhotosList: action.payload,
      }
    case GET_STATION_PHOTOS_FAIL:
      return {
        ...state,
        isGetPhotosLoading: false,
        getStationPhotosFail: true,
        getStationPhotosSuccess: false,
        stationPhotosList: [],
      }
    case CLEAR_STATIONS_PHOTO:
      return {
        ...state,
        isGetPhotosLoading: false,
        isDeleteLoading: false,
        getStationPhotosFail: false,
        getStationPhotosSuccess: false,
        stationPhotoUploadedSuccess: false,
        stationPhotoUploadedFail: false,
        stationPhotoDeleteSuccess: false,
        stationPhotoDeleteFail: false,
        stationPhotosList: [],
      }
    case STATION_PHOTO_UPLOAD_REQUEST:
      return {
        ...state,
        stationPhotoUploadedSuccess: false,
        stationPhotoUploadedFail: false,
      }
    case STATION_PHOTO_UPLOAD_SUCCESS:
      return {
        ...state,
        stationPhotoUploadedSuccess: true,
        stationPhotoUploadedFail: false,
      }
    case STATION_PHOTO_UPLOAD_FAIL:
      return {
        ...state,
        stationPhotoUploadedFail: true,
        stationPhotoUploadedSuccess: false,
      }
    case STATION_PHOTO_DELETE_REQUEST:
      return {
        ...state,
        isDeleteLoading: true,
        stationPhotoDeleteSuccess: false,
        stationPhotoDeleteFail: false,
      }
    case STATION_PHOTO_DELETE_SUCCESS:
      return {
        ...state,
        isDeleteLoading: false,
        stationPhotoDeleteSuccess: true,
        stationPhotoDeleteFail: false,
      }
    case STATION_PHOTO_DELETE_FAIL:
      return {
        ...state,
        isDeleteLoading: false,
        stationPhotoDeleteFail: true,
        stationPhotoDeleteSuccess: false,
      }
    default:
      return state
  }
}

export default StationPhotosReducer
